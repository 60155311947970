import { DateTime } from 'luxon';

import { ObjectDatesAsStrings } from 'core/effects/apiCall/dateTimeTransformations';
import BaseModel from 'core/models/Base';
import { Gender } from 'core/models/CoreUser';
import Country from 'core/models/Country';
import Analysis from 'lists/models/Analysis';
import { AuthorityDetail } from 'lists/models/Authority';
import Federation from 'lists/models/Federation';
import FileTemplate from 'lists/models/FileTemplate';
import Laboratory from 'lists/models/Laboratory';
import Pool from 'lists/models/Pool';
import Sport from 'lists/models/Sport';
import SportDiscipline from 'lists/models/SportDiscipline';
import { AbpQuestionnaireData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/AbpQuestionnaire';
import { AthleteData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/Athlete';
import { BloodData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/Blood';
import { CommentsData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/Comments';
import { GeneralDataData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/GeneralData';
import { GeneralInfoData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/GeneralInfo';
import { MedicationData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/Medication';
import { NotificationData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/Notification';
import { UrineData } from 'planning/containers/TestDetailPage/tabs/ResultsTab/sections/Urine';

import Mission from './Mission';

export type TestResult = GeneralDataData &
  GeneralInfoData &
  NotificationData &
  AthleteData &
  UrineData &
  BloodData &
  AbpQuestionnaireData &
  MedicationData &
  CommentsData &
  BaseModel;

export enum SamplingStatus {
  NOT_PROVIDED = 0,
  COLLECTED = 1,
  NOT_REQUIRED = 2,
  NOT_POSSIBLE = 3,
  REFUSED = 4,
}

export enum NotificationArrivalDelayReasons {
  PHONE_NOTIFICATION = 1,
  CHAPERONE_NOTIFICATION = 2,
  OTHER = 3,
}

type ResultAthlete = {
  firstName: string | null;
  middleName: null | string;
  lastName: string | null;
  fullName?: string | null;
  gender: Gender;
  dateOfBirth: Date | null;
  nationalityId: number | null;
  street: null | string;
  address: null | string;
  city: null | string;
  zip: null | string;
  state: null | string;
  countriesId: number | null;
  email: null | string;
  phone: null | string;
  sportDisciplinesId: number | null;
  poolsId: number | null;
  sportDiscipline?: SportDiscipline;
};

export type ResultEdta = {
  answer1: boolean;
  answer2temp: number;
  answer2unit: 'c' | 'f';
  answer3: boolean;
  answer3where: string | null;
  answer4: boolean;
  answer4date: Date | null;
  answer4volume?: number | null;
  answer4cause: string | null;
  answer5: boolean;
  answer5specific: string | null;
  answer5duration: string | null;
  answer5periodFrom: Date | null;
  answer5periodTo: Date | null;
  answer5altitude: string | null;
  answer5location: string | null;
  answer6: boolean;
  answer6device: string | null;
  answer6use: string | null;
  answer7: boolean;
  answer7competition: string | null;
  answer8: boolean;
  answer8specific: string | null;
  dcosId: number | null;
  dcoSignature: string | null;
  athleteSignature: string | null;
  performedAt: DateTime | null;
  dco?: { fullName: string };
};

type ResultNotification = {
  notificationType: 'm' | 'p' | null;
  notifiedAt: DateTime | null;
  notificationDcoName?: string | null;
  athleteFirstName: string | null;
  athleteMiddleName?: string | null;
  athleteLastName: string | null;
  dcoSignature: string | null;
  poolsId: number | null;
  oneHourSlot: 'in' | 'out' | null;
  delayedDeadline: string | null;
  place?: string | null;
  race?: string | null;
  eventName?: string | null;
  street?: string | null;
  address?: string | null;
  city: string | null;
  zip?: string | null;
  state?: string | null;
  countriesId: number | null;
  createLocation?: boolean;
  locationName: string | null;
};

type ResultSample = {
  code: string | null;
  type: number;
  relevantPartialCodes: string | null;
  relevantPartialVolumes: string | null;
  performedAt: DateTime | null;
  isSamplePossible: boolean;
  isPartial: boolean;
  athleteSignature: string | null;
  dcosId: number | null;
  dcoSignature: string | null;
  witnessName: string | null;
  witnessSignature: string | null;
  gravity: number | null;
  volume: number | null;
  tempLogger: string | null;
  receivedByLab: boolean;
  receivedByLabAt: string | null;
  resultReported: boolean | null;
  resultReportedAt: string | null;
};

export type Substance = {
  id: string;
  name: string;
  dose: string;
  unit: string;
  lastTakenAt: Date | null;
  frequency: string;
  route: string;
};

export type TueItem = {
  id: string;
  name: string;
  authority: string;
};

export interface TestResults extends BaseModel {
  isFinal: boolean;
  cancelReason: number | null;
  cancelComment: string | null;
  language: string;
  timeZonesId: number;
  steps?: string;
  testResultNotificationsId?: number | null;
  athletesId: number | null;
  sportDisciplinesId: number | null;
  athleteConsentSignature: string | null;
  athleteAuthorityConsentSignature: string | null;
  athleteAdamsConsentSignature: string | null;
  dcoArbitrationConsentSignature: string | null;
  athleteArbitrationConsentSignature: string | null;
  athleteConfirmationSignature: string | null;
  athleteComment: string | null;
  athleteRepresentativeName: string | null;
  athleteRepresentativeRole: string | null;
  athleteRepresentativeSignature: string | null;
  athleteRepresentativeOptionalName: string | null;
  athleteRepresentativeOptionalRole: string | null;
  athleteRepresentativeOptionalSignature: string | null;
  isAthleteIdProvided: boolean;
  isPersonallyKnown?: boolean;
  documentTypesId?: number | null;
  documentNumber?: string | null;
  documentName?: string | null;
  nameOnDocument?: string | null;
  isRepresentativeNeeded: boolean;
  socialSecurityNumber?: string | null;
  club?: string | null;
  team?: string | null;
  backNumber?: string | null;
  coachName: string | null;
  doctorName: string | null;
  urineAthleteSignature: string | null;
  urineSamplingStatus?: SamplingStatus;
  urineManufacturersId: number | null;
  bcosId: number | null;
  bcoSignature: string | null;
  bloodDcosId: number | null;
  bloodDcoSignature: string | null;
  bloodAthleteSignature: string | null;
  bloodSamplingStatus: SamplingStatus;
  bloodManufacturersId: number | null;
  dbsSamplingStatus: SamplingStatus;
  dbsAthleteSignature: string | null;
  dbsManufacturersId: number | null;
  dbsManufacturerKitsId: number | null;
  dbsDcosId: number | null;
  dbsDcoSignature: string | null;
  dbsBcosId: number | null;
  dbsBcoSignature: string | null;
  dcosId?: number | null;
  dcoComment: string | null;
  dcoConfirmationSignature: string | null;
  dco2ndOfficialName?: string | null;
  dco2ndOfficialRole?: string | null;
  dco2ndOfficialSignature: string | null;
  veinPuncture?: number | null;
  medications: string | null;
  advancedMedications: Substance[] | null;
  tue: string | null;
  advancedTue: TueItem[] | null;
  consentForResearch: boolean;
  consentForResearchSignature: string | null;
  testResultEdtaId?: number | null;
  email: string | null;
  confirmedBy?: DateTime | null;
  arrivedAt: DateTime | null;
  performedAt: DateTime | null;
  creatorClient?: string | null;
  locationsId: number | null;
  testResultEdta?: ResultEdta;
  testResultNotification?: ResultNotification;
  samples: ResultSample[];
  athlete: ResultAthlete;
  sportDiscipline?: SportDiscipline;
  arrivalDelayReason: NotificationArrivalDelayReasons;
  arrivalDelayComment: string | null;
  creator?: { fullName: string };
}

export type TestResultsRequest = {
  isFinal: boolean;
  cancelReason: number | null;
  cancelComment: string | null;
  language: string;
  timeZonesId?: number;
  steps?: string;
  testResultNotificationsId?: number | null;
  athletesId: number | null;
  sportDisciplinesId: number | null;
  athleteConsentSignature: string | null;
  athleteAuthorityConsentSignature: string | null;
  athleteAdamsConsentSignature: string | null;
  dcoArbitrationConsentSignature: string | null;
  athleteArbitrationConsentSignature: string | null;
  athleteConfirmationSignature: string | null;
  athleteComment: string | null;
  athleteRepresentativeName: string | null;
  athleteRepresentativeRole: string | null;
  athleteRepresentativeSignature: string | null;
  athleteRepresentativeOptionalName: string | null;
  athleteRepresentativeOptionalRole: string | null;
  athleteRepresentativeOptionalSignature: string | null;
  isAthleteIdProvided: boolean;
  isPersonallyKnown?: boolean;
  documentTypesId?: number | null;
  documentNumber?: string | null;
  documentName?: string | null;
  nameOnDocument?: string | null;
  isRepresentativeNeeded: boolean | null;
  socialSecurityNumber?: string | null;
  club?: string | null;
  team?: string | null;
  backNumber?: string | null;
  coachName: string | null;
  doctorName: string | null;
  urineAthleteSignature: string | null;
  urineSamplingStatus?: SamplingStatus;
  bcosId: number | null;
  bcoSignature: string | null;
  bloodDcosId: number | null;
  bloodDcoSignature: string | null;
  bloodAthleteSignature: string | null;
  bloodSamplingStatus: SamplingStatus;
  dcosId?: number | null;
  dcoComment: string | null;
  dcoConfirmationSignature: string | null;
  dco2ndOfficialName?: string | null;
  dco2ndOfficialRole?: string | null;
  dco2ndOfficialSignature: string | null;
  veinPuncture?: number | null;
  medications: string | null;
  advancedMedications: ObjectDatesAsStrings<Substance>[] | null;
  tue: string | null;
  advancedTue: TueItem[] | null;
  consentForResearch: boolean;
  consentForResearchSignature: string | null;
  testResultEdtaId?: number | null;
  email: string | null;
  confirmedBy?: number | null;
  arrivedAt: string | null;
  arrivalDelayReason: NotificationArrivalDelayReasons;
  arrivalDelayComment: string | null;
  performedAt: string | null;
  creatorClient?: string | null;
  locationsId: number | null;
  bloodManufacturersId: number | null;
  urineManufacturersId: number | null;
  dbsManufacturersId: number | null;
  dbsManufacturerKitsId: number | null;
  dbsDcosId: number | null;
  dbsDcoSignature: string | null;
  dbsBcosId: number | null;
  dbsBcoSignature: string | null;
  dbsAthleteSignature: string | null;
  dbsSamplingStatus: SamplingStatus;
  testResultEdta?: ObjectDatesAsStrings<ResultEdta>;
  testResultNotification?: ObjectDatesAsStrings<ResultNotification>;
  samples: ObjectDatesAsStrings<ResultSample>[];
  athlete: ObjectDatesAsStrings<ResultAthlete>;
  uploadables?: {
    dcfFile: File;
    abpFile?: File;
  };
};

/** required test resources to create default form state */
export interface TestDetailResources {
  ['sports/disciplines']?: SportDiscipline[];
  ['file-templates']?: FileTemplate[];
  federations?: Federation[];
  authorities?: AuthorityDetail[];
  analyses?: Analysis[];
  countries?: Country[];
  locations?: Location[];
  missions?: Mission[];
  labs?: Laboratory[];
  sports?: Sport[];
  pools?: Pool[];
  [key: string]: any;
}
