import Base from 'core/models/Base';

import { OrderItem } from './OrderItem';

export enum OrderStatus {
  NEW = 0,
  SUBMITTED = 1,
  ACCEPTED = 2,
  DECLINED = 3,
}

export interface Order extends Base {
  testingAuthoritiesId: number | null;
  resultAuthoritiesId: number | null;
  initiatorAuthoritiesId: number | null;
  resultsAuthority?: {
    id: number;
    fullName: string;
  };
  testingAuthority?: {
    id: number;
    fullName: string;
    shortName: string;
  };
  initiatorAuthority?: {
    id: number;
    fullName: string;
  };
  orderedBy: number;
  status: OrderStatus;
  items: OrderItem[];
}
