import { useCallback } from 'react';

import { AccreditationsId } from 'app/models/Accreditation';
import User from 'app/models/User';
import { Option } from 'core/components/DropDown';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useHumanReadableAvailability } from 'personnel/hooks/useHumanReadableAvailability';
import AvailabilityCategory from 'personnel/models/AvailabilityCategory';

export type OfficerReducerOptions = {
  availabilityCategories?: AvailabilityCategory[];
  options?: {
    requiredAccreditationIds?: AccreditationsId[];
    beforeReducer?: (list: User[], chosen: number[] | null) => User[];
    afterReducer?: (list: Option[], chosen: number[] | null) => Option[];
  };
};

const useOfficersReducer = ({ availabilityCategories, options }: OfficerReducerOptions) => {
  const getHumanReadableAvailability = useHumanReadableAvailability(availabilityCategories);
  const labelsEnabled = useBoolClientOption('enableLabels');
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');

  const reducer = useCallback(
    (list: User[], chosen: number[] | null) => {
      const chosenSet = new Set(chosen);

      const requiredAccreditationIds = options?.requiredAccreditationIds;
      const beforeReducer = options?.beforeReducer;
      const afterReducer = options?.afterReducer;

      const beforeReduced = beforeReducer ? beforeReducer(list, chosen) : list;

      const reduced = beforeReduced
        .filter((officer) => {
          // Selected officer is always included
          if (chosenSet.has(officer.id)) return true;

          const accreditedFor = new Set(
            officer.accreditations?.map((accreditation) => accreditation.id)
          );

          return (
            officer.active &&
            (!requiredAccreditationIds ||
              requiredAccreditationIds.some((required) => accreditedFor.has(required)))
          );
        })
        .map((item) => {
          const secondary = [];
          if (availabilitiesEnabled) {
            secondary.push(getHumanReadableAvailability(item.availabilities));
          }
          if (labelsEnabled) {
            if (Array.isArray(item.labels) && item.labels.length > 0) {
              secondary.push(item.labels.join(', '));
            } else if (!availabilitiesEnabled) {
              // Secondary row has to be always available if at least one can have labels and won't have availability.
              // Otherwise, the dropdown would break.
              secondary.push('-');
            }
          }

          return {
            id: item.id,
            name: item.fullName || item.email,
            secondary: secondary.length > 0 ? secondary.join(', ') : undefined,
            extra: item,
          };
        });

      // Align secondary - either all or none have it - this otherwise breaks list of options
      const anyHasSecondary = reduced.some((item) => !!item.secondary);
      const items = anyHasSecondary
        ? reduced.map((item) => ({ ...item, secondary: item.secondary || '-' }))
        : reduced.map((item) => {
            const withoutSecondary = { ...item };
            delete withoutSecondary.secondary;
            return withoutSecondary;
          });

      return afterReducer ? afterReducer(items, chosen) : items;
    },
    [
      options?.requiredAccreditationIds,
      options?.afterReducer,
      labelsEnabled,
      options?.beforeReducer,
      availabilitiesEnabled,
      getHumanReadableAvailability,
    ]
  );

  return reducer;
};

export default useOfficersReducer;
