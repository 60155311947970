import Test, { TestStatus } from 'planning/models/Test';

/**
 * Detect when Test is in status that should not be possible to remove assigned roles
 */
export default function getIsDisableRemoveAssignment(data?: Test) {
  return (
    !!data &&
    [
      TestStatus.CANCELLED,
      TestStatus.UNSUCCESSFUL,
      TestStatus.COMPLETED,
      TestStatus.IN_PROGRESS,
    ].includes(data.status)
  );
}
