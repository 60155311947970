import { push } from 'connected-react-router';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { entities } from 'app/entity';
import { RolesId } from 'app/models/Role';
import { FormContext } from 'core/components/Form';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import IconButton from 'core/components/IconButton';
import Tooltip from 'core/components/Tooltip';
import Avatar from 'core/containers/Avatar';
import usePermission from 'core/hooks/usePermission';

import { TeamMemberData } from '../useTeamInputMapping';

import { ListWrapper } from './styled';

const emptyData = [] as TeamMemberData[];

/**
 * Read-only Team Members list
 */
const MembersList: FC<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const canSeeDcoDetail = usePermission(`users:get[roles:${RolesId.DCO}]`);
  const assigned = (useFormFieldValue('assignedMembers') || emptyData) as TeamMemberData[];

  const { isFieldInViewMode } = useContext(FormContext);
  const viewMode = isFieldInViewMode('assignedMembers');

  return (
    <ListWrapper>
      {assigned.map((member) => {
        const tooltip = (
          <Tooltip content={member.fullName}>
            <Avatar
              endpoint={entities.user.api().avatar(member.usersId)}
              storageKey={member.avatarStorageKey}
              tooltipPlacement="bottom"
              title={member.fullName}
              edit={false}
              size="normal"
            />
          </Tooltip>
        );

        return canSeeDcoDetail ? (
          <Link key={member.usersId} to={entities.user.urls().detail(member.usersId)}>
            {tooltip}
          </Link>
        ) : (
          <>{tooltip}</>
        );
      })}

      {/* Using isLink ended with "TypeError: t is null", so it wasn't possible to use proper anchor for accessibility */}
      <IconButton
        tooltip={viewMode ? t('View Team') : t('Edit Team')}
        icon={viewMode ? 'more_horiz' : 'edit'}
        onClick={() => {
          // We expect the current tab is Detail - it can be mission/test create or edit
          const teamUrl = location.pathname.replace(/(.*)detail(.*)/, '$1team$2');
          dispatch(push(teamUrl));
        }}
      />
    </ListWrapper>
  );
};

export default MembersList;
