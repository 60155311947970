import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import Tooltip from 'core/components/Tooltip';
import formatNumberAbbreviated from 'core/functions/formatNumberAbbreviated';

import { StyledCounts } from './styled';

interface Props {
  total?: null | number;
  filtered?: number;
  disableTotalCount?: boolean;
  portalId?: string;
}

const Counts: FC<Props> = ({ total, filtered, disableTotalCount, portalId }) => {
  const { t } = useTranslation();
  const hasPortalId = !!portalId;

  if (typeof filtered !== 'number') {
    return null;
  }

  const formattedTotal =
    // we check only null and undefined, because 0 is a valid number
    total !== null && total !== undefined ? formatNumberAbbreviated(total) : 'N/A';

  const countsEl = (
    <StyledCounts data-cy="datagrid-counts" aria-label={t('Data grid items count')}>
      {disableTotalCount
        ? t('Showing {{count}} results', { count: filtered })
        : `${filtered} / ${formattedTotal}`}
    </StyledCounts>
  );

  const totalCountNotAvailable = total === null || total === undefined;

  // We defend ourselves from displaying gibberish in case we don't have any counts
  const component = totalCountNotAvailable ? (
    <Tooltip content={t('Total count not available')}>{countsEl}</Tooltip>
  ) : (
    countsEl
  );

  const el = hasPortalId && document.getElementById(portalId);

  // TODO: https://cannypack.atlassian.net/browse/MODFE-2773
  return (
    <>
      {hasPortalId
        ? el
          ? createPortal(component, document.getElementById(portalId)!)
          : null
        : component}
    </>
  );
};

export default Counts;
