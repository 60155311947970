import { getIn, useFormikContext } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '..';

import { StyledAnnotation } from './styled';

interface OwnProps {
  name: string;
  onDiffReset?: () => void;
}

const TouchedAnnotation: FC<OwnProps> = ({ name, onDiffReset }) => {
  const { setFieldTouched, initialValues, setFieldValue, values, errors } = useFormikContext();
  const { saveOperation, customOperations } = useContext(FormContext);

  const { t } = useTranslation();

  const onReset = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onDiffReset?.();
      setFieldValue(name, getIn(initialValues, name));
      setFieldTouched(name, false);
      saveOperation({
        fieldName: name,
        operation: 'replace',
        type: 'COMMON',
      });
    },
    [setFieldValue, name, setFieldTouched, initialValues, saveOperation, onDiffReset]
  );

  const currentFieldOperation = useMemo(() => {
    return customOperations.find((it) => it.fieldName === name);
  }, [customOperations, name]);

  return useMemo(() => {
    const isDifferent = !!(
      !getIn(errors, name) && getIn(initialValues, name) !== getIn(values, name)
    );

    if (currentFieldOperation && currentFieldOperation.operation) {
      // it's custom operation like add or remove
      const isRemove = currentFieldOperation.operation === 'remove';
      return (
        <StyledAnnotation>
          {currentFieldOperation.description && (
            <>
              {currentFieldOperation.description}{' '}
              {(isDifferent || isRemove) && (
                <a href={`#reset-touched-${name}`} onClick={onReset}>
                  {t('Reset')}
                </a>
              )}{' '}
            </>
          )}
        </StyledAnnotation>
      );
    }

    if (isDifferent) {
      return (
        <StyledAnnotation>
          {t('This field will be replaced with new value.')}{' '}
          <a href={`#reset-touched-${name}`} onClick={onReset}>
            {t('Reset')}
          </a>
        </StyledAnnotation>
      );
    }

    return null;
  }, [errors, initialValues, values, name, t, onReset, currentFieldOperation]);
};

export default TouchedAnnotation;
