import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import {
  useGetSampleTypeFilterName,
  useGetSampleTypeFilterValue,
} from 'common/filters/useSampleTypeFilter';
import IconCellRenderer from 'core/containers/DataGrid/FastCellRenderers/IconCellRendererNew';
import { ColumnDef } from 'core/containers/DataGrid/props';
import { t } from 'core/i18n';
import { SampleType, getSampleTypeName, isBlood, isDbs, isUrine } from 'planning/models/Sample';

export const sampleTypeIcons = [
  {
    id: 'urine',
    content: (data: { urineAnalysesCount: number }) =>
      data.urineAnalysesCount ? t('Urine analyses') : t('No Urine analyses'),
    color: (data: { urineAnalysesCount: number }) =>
      data.urineAnalysesCount ? theme.color.status.orange : theme.color.orange600,
    size: 1.25,
    name: 'urine',
  },
  {
    id: 'blood',
    content: (data: { bloodAnalysesCount: number }) =>
      data.bloodAnalysesCount ? t('Blood analyses') : t('No Blood analyses'),
    color: (data: { bloodAnalysesCount: number }) =>
      data.bloodAnalysesCount ? theme.color.status.red : theme.color.red600,
    size: 1.25,
    name: 'blood',
  },
  {
    id: 'dbs',
    content: (data: { dbsAnalysesCount: number }) =>
      data.dbsAnalysesCount ? t('DBS analyses') : t('No DBS analyses'),
    color: (data: { dbsAnalysesCount: number }) =>
      data.dbsAnalysesCount ? theme.color.burgundy : theme.color.burgundy600,
    size: 1.25,
    name: 'dbs',
  },
];

export const sampleTypesToGrid = (identifier: string = 'type') => [
  {
    id: 'urine',
    content: (data: { [key: string]: SampleType }) => getSampleTypeName(data[identifier]),
    color: () => theme.color.status.orange,
    size: 1.25,
    name: 'urine',
    hidden: (data: { [key: string]: SampleType }) => !isUrine(data[identifier]),
  },
  {
    id: 'blood',
    content: (data: { [key: string]: SampleType }) => getSampleTypeName(data[identifier]),
    color: () => theme.color.status.red,
    size: 1.25,
    name: 'blood',
    hidden: (data: { [key: string]: SampleType }) => !isBlood(data[identifier]),
  },
  {
    id: 'dbs',
    content: (data: { [key: string]: SampleType }) => getSampleTypeName(data[identifier]),
    color: () => theme.color.burgundy,
    size: 1.25,
    name: 'dbs',
    hidden: (data: { [key: string]: SampleType }) => !isDbs(data[identifier]),
  },
];

export const useSampleTypeColumn = (isFilter: boolean = false): ColumnDef => {
  const { t } = useTranslation();
  const getSampleTypeFilterValue = useGetSampleTypeFilterValue();
  const getSampleTypeFilterName = useGetSampleTypeFilterName();

  return {
    headerName: t('Sample Type'),
    field: 'bloodAnalysesCount',
    resizable: true,
    sortable: false,
    minWidth: 150,
    width: 300,
    cellRendererParams: {
      icons: sampleTypeIcons,
    },
    cellRenderer: (params: ICellRendererParams) => <IconCellRenderer params={params} />,
    ...(isFilter
      ? {
          customFilter: {
            filterKey: 'sampleType',
            getFilterValue: (data: {
              bloodAnalysesCount: number;
              urineAnalysesCount: number;
              dbsAnalysesCount: number;
            }) =>
              getSampleTypeFilterValue(
                data.bloodAnalysesCount,
                data.urineAnalysesCount,
                data.dbsAnalysesCount
              ),
            getFilterByName: (data: {
              bloodAnalysesCount: number;
              urineAnalysesCount: number;
              dbsAnalysesCount: number;
            }) =>
              getSampleTypeFilterName(
                data.bloodAnalysesCount,
                data.urineAnalysesCount,
                data.dbsAnalysesCount
              ),
          },
        }
      : {}),
  };
};
