import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiDropdownOptionsFilter from 'core/hooks/useMultiDropdownOptionsFilter';
import { TestSituation } from 'planning/models/Test';

export const useTestSituationOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      { name: t('OC'), id: TestSituation.OC },
      { name: t('TC'), id: TestSituation.TC },
      { name: t('IC'), id: TestSituation.IC },
    ],
    [t]
  );
};

export const useTestSituationsFilter = () => {
  const { t } = useTranslation();
  const testSituationOptions = useTestSituationOptions();

  return useMultiDropdownOptionsFilter(
    t('Test Situation'),
    'testSituationsId',
    [
      {
        id: 'any',
        name: t('Any'),
      },
      ...testSituationOptions,
    ],
    ['any']
  );
};
