import { FC } from 'react';

import theme from 'app/theme';
import Badge from 'core/components/Badge';
import MaterialIcon from 'core/components/MaterialIcon';
import Tooltip from 'core/components/Tooltip';

import { TeamMemberData } from '../../../../useTeamInputMapping';

import getTeamMemberStatusColor from './getTeamMemberStatusColor';
import { Wrapper } from './styled';
import useGetStatusName from './useGetStatusName';

const StatusCell: FC<{ user: TeamMemberData }> = ({ user }) => {
  const color = getTeamMemberStatusColor(user.status);
  const getStatusName = useGetStatusName();

  const statusName = getStatusName(user.status);

  return (
    <Wrapper>
      <Badge variant={`${color}Background`} color={theme.color[color]}>
        {statusName}
      </Badge>
      {user.statement && (
        <Tooltip content={user.statement}>
          <MaterialIcon icon="notes" />
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default StatusCell;
