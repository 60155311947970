import theme from 'app/theme';
import { TeamMemberStatus } from 'planning/models/TeamMember';

// Extract only keys that end with "Background"
type BackgroundKeys = Extract<keyof typeof theme.color, `${string}Background`>;

// Get the actual color names by removing "Background" suffix
type ColorWithBackground = BackgroundKeys extends `${infer Base}Background`
  ? Base extends keyof typeof theme.color
    ? Base
    : never
  : never;
/**
 * Human readable team member status color
 */

export default function getTeamMemberStatusColor(status?: TeamMemberStatus): ColorWithBackground {
  switch (status) {
    case TeamMemberStatus.ACCEPTED:
      return 'success';

    case TeamMemberStatus.AWAITING:
      return 'info';

    case TeamMemberStatus.CONFIRMED:
      return 'success';

    case TeamMemberStatus.DECLINED:
      return 'error';

    case TeamMemberStatus.SELECTED:
      return 'info';

    default:
      return 'info';
  }
}
