import Base from 'core/models/Base';
import CoreUser from 'core/models/CoreUser';
import { UserInvoiceData } from 'finance/models/DCOInvoice';
import { EntityLabel } from 'lists/models/Label';
import AccreditationCard from 'personnel/models/AccreditationCard';
import { ProfileAvailability } from 'personnel/models/ProfileAvailability';

import Accreditation from './Accreditation';

export interface UserAccreditation extends Base {
  accreditationsId: number;
}

export default interface User extends CoreUser {
  accreditations?: Accreditation[];
  userAccreditations?: UserAccreditation[];
  authoritiesId: null | number;
  invoiceData?: UserInvoiceData | null;
  accreditationCards?: AccreditationCard[];
  availabilities: ProfileAvailability[];
  availabilityStatus?: number;
  labels: string[] | null;
  labelsId: number[] | null;
  userLabels: EntityLabel[] | null;
  signature: string | null;
  hasSmsContact: boolean;
}

export enum UserContactType {
  PHONE = 'phone',
  MOBILE = 'mobile',
  FAX = 'fax',
  EMAIL = 'email',
}
