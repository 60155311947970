import { FC, useEffect, useMemo, useRef } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import theme from 'app/theme';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { stringify } from 'core/functions/qs';
import { useResources } from 'core/hooks/useResource';
import Athlete, { AthletePreviousDeclaration } from 'personnel/models/Athlete';
import AdvancedMedicationsCard from 'planning/components/AdvancedMedicationsCard';
import AdvancedTueCard from 'planning/components/AdvancedTueCard';

interface Props {}

const PreviousDeclarations: FC<Props> = () => {
  const { t } = useTranslation();
  const athletesId: number | null = useFormFieldValue('athletesId');
  const alreadyFetchedAthletesId = useRef<number | null>(null);

  type IncludedDeclarations =
    | { athletesId: number; previousDeclarations: AthletePreviousDeclaration }
    | undefined;

  const athleteInclude = useIncludeOptions('athletesId') as Athlete[];
  const includedDeclarations: IncludedDeclarations = useMemo(() => {
    if (
      athleteInclude?.length &&
      athleteInclude[0].previousDeclarations &&
      athleteInclude[0].id === athletesId
    ) {
      return {
        athletesId: athleteInclude[0].id,
        previousDeclarations: athleteInclude[0].previousDeclarations,
      };
    }
    return undefined;
  }, [athleteInclude, athletesId]);

  const {
    data: previousDeclarations,
    reload: loadPreviousDeclarations,
    isLoading,
  } = useResources<AthletePreviousDeclaration>(
    `${entities.athlete.api().previousDeclarations(athletesId || -1)}?${stringify({
      limit: 1,
      order: 'performedAt',
      sort: 'desc',
    })}`,
    {
      autoload: false,
    }
  );

  const currentDeclarations =
    includedDeclarations && includedDeclarations.athletesId === athletesId
      ? includedDeclarations.previousDeclarations
      : previousDeclarations
        ? previousDeclarations[0]
        : undefined;

  const hasDeclarations =
    currentDeclarations &&
    (currentDeclarations.advancedMedications?.length || currentDeclarations.advancedTue?.length);

  useEffect(() => {
    if (
      athletesId &&
      athletesId !== alreadyFetchedAthletesId.current &&
      (!includedDeclarations || includedDeclarations.athletesId !== athletesId)
    ) {
      loadPreviousDeclarations();
    }
    alreadyFetchedAthletesId.current = athletesId;
  }, [athletesId, loadPreviousDeclarations, includedDeclarations]);

  return (
    <>
      <PaperContainer title={t('Previous Declarations')}>
        {isLoading && (
          <ContentLoader
            width="100%"
            height="2rem"
            foregroundColor={theme.color.gray200}
            backgroundColor={theme.color.gray100}
            title={t('Loading...')}
            arial-label={t('Loading indicator')}
          >
            <rect x="0" y="0" rx="5" ry="5" width="100%" height={16} />
          </ContentLoader>
        )}

        {hasDeclarations && (
          <Row>
            {currentDeclarations.advancedMedications?.length && (
              <FullRowCell>
                <AdvancedMedicationsCard
                  fieldName="medications.advancedMedications"
                  viewMode
                  value={currentDeclarations.advancedMedications}
                />
              </FullRowCell>
            )}
            {currentDeclarations.advancedTue?.length && (
              <FullRowCell>
                <AdvancedTueCard viewMode value={currentDeclarations.advancedTue} />
              </FullRowCell>
            )}
          </Row>
        )}

        {!isLoading && athletesId && !hasDeclarations && <em>{t('There are no items.')}</em>}

        {!athletesId && <em>{t('Choose an athlete to see their previous declarations.')}</em>}
      </PaperContainer>
    </>
  );
};

export default PreviousDeclarations;
