import { FC } from 'react';

import { AccreditationsRole } from 'app/models/Accreditation';
import Switch from 'core/components/Switch';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import RoleTooltip from './RoleTooltip';
import { RoleCell } from './styled';
interface RIP {
  setRole: (role: AccreditationsRole, toggle: boolean) => void;
  disableRemoveRoles?: AccreditationsRole[];
  disableChangeLeadDco?: boolean;
  initialValue?: TeamMemberData;
  role: AccreditationsRole;
  value?: TeamMemberData;
  fieldName: string;
  disabled?: boolean;
}

const RoleCellItem: FC<RIP> = ({
  disableChangeLeadDco,
  disableRemoveRoles,
  disabled,
  fieldName,
  setRole,
  value,
  role,
}) => {
  const isDisabled =
    disableChangeLeadDco ||
    disabled ||
    (disableRemoveRoles && disableRemoveRoles.includes(role)) ||
    (value && !value.allowedRoles.includes(role));
  const selectedRoles = [...(value?.roles || [])];

  return (
    <RoleTooltip
      disableChangeLeadDco={disableChangeLeadDco}
      disableRemove={!!disabled}
      value={value}
      role={role}
    >
      <RoleCell>
        <Switch
          onChange={(value) => setRole(role, value)}
          value={selectedRoles.includes(role)}
          name={`${fieldName}.${role}`}
          id={`${fieldName}.${role}`}
          disabled={isDisabled}
          label={''}
        />
      </RoleCell>
    </RoleTooltip>
  );
};

export default RoleCellItem;
