import Base from 'core/models/Base';

export enum LabelEntity {
  TESTS = 'Tests',
  AUTHORITIES = 'Authorities',
  ATHLETES = 'Athletes',
  FEDERATIONS = 'Federations',
  LABS = 'Labs',
  USERS = 'Users',
  ORDER_ITEMS = 'OrderItems',
}

export interface Label extends Base {
  name: string;
  description: string | null;
  color: string | null;
  entityName: LabelEntity;
}

export type EntityLabel = {
  labelsId: number;
} & Label;

export interface LabelGrid extends Label {}

export interface LabelCreate extends Omit<Label, keyof Base> {}
