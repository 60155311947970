import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import Tooltip from 'core/components/Tooltip';
import { getParticipantsMap } from 'finance/containers/DCOInvoiceDetailPage/hooks/useTestPopover';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

interface Props {
  disableChangeLeadDco?: boolean;
  role: AccreditationsRole;
  disableRemove: boolean;
  children: ReactElement;
  value?: TeamMemberData;
}

const RoleTooltip: FC<Props> = ({ disableChangeLeadDco, children, role, value, disableRemove }) => {
  const isNotAllowed = value && !value.allowedRoles.includes(role);
  const { t } = useTranslation();

  let tooltip;

  if (isNotAllowed) {
    tooltip = t('User has no Accreditation for this role');
  } else if (disableChangeLeadDco) {
    tooltip = t('Cannot change {{role}} role', {
      role: getParticipantsMap(AccreditationsRole.LEAD_DCO),
    });
  } else if (disableRemove) {
    tooltip = t("It's not possible to remove role");
  }

  if (tooltip) {
    return <Tooltip content={tooltip}>{children}</Tooltip>;
  }

  return <>{children}</>;
};

export default RoleTooltip;
