import { FC } from 'react';

import { entities } from 'app/entity';
import Avatar from 'core/containers/Avatar';

import { TeamMemberData } from '../../../../useTeamInputMapping';

import { PersonalInfo, Wrapper } from './styled';

interface Props {
  availabilities: { [usersId: number]: string };
  user: TeamMemberData & { id: number };
}

const BasicInfoCell: FC<Props> = ({ user, availabilities }) => {
  const info = availabilities[user.usersId] ? [availabilities[user.usersId]] : [];

  return (
    <Wrapper>
      <Avatar
        endpoint={entities.user.api().avatar(user.id)}
        storageKey={user.avatarStorageKey}
        tooltipPlacement="bottom"
        title={user.fullName}
        withTooltip={true}
        edit={false}
        size="normal"
        noMargin
      />
      <PersonalInfo>
        <h3>{user.fullName}</h3>
        <span>{[...info, user.labels].join(', ') || '-'}</span>
      </PersonalInfo>
    </Wrapper>
  );
};

export default BasicInfoCell;
