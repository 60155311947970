import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import FormDropDown, { Props as FormDropDownProps } from 'core/components/FormDropDown';

interface Props extends FormDropDownProps {
  rightElement?: React.ReactNode;
}

const FormDropdownWithRightElement: React.FC<Props> = ({ rightElement, ...rest }) => {
  return (
    <FlexRow>
      <FlexCell flex={1}>
        <FormDropDown {...rest} />
      </FlexCell>

      {rightElement ? <FlexCell>{rightElement}</FlexCell> : null}
    </FlexRow>
  );
};

export default FormDropdownWithRightElement;
