import { PropsWithChildren } from 'react';

import { Badge } from './styled';

export interface BadgeCellParams {
  color?: string;
  title?: string;
}

const BadgeCellRenderer = ({ title, color, ...rest }: PropsWithChildren<BadgeCellParams>) => {
  return title ? (
    <Badge $color={color} {...rest}>
      {title}
    </Badge>
  ) : null;
};

export default BadgeCellRenderer;
