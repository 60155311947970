import styled from 'styled-components/macro';

export const PersonalInfo = styled.div`
  flex: 1;
  gap: 0.25rem;
  display: flex;
  padding: 0 1rem;
  overflow-x: hidden;
  flex-direction: column;

  h3 {
    line-height: 1;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.875rem;
  }

  span {
    display: block;
    overflow: hidden;
    max-height: 1rem;
    font-size: 0.75rem;
    text-overflow: ellipsis;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 3rem;
  align-items: center;
  justify-content: flex-start;
`;
