import { useFormikContext } from 'formik';
import { FC, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import { RolesId } from 'app/models/Role';
import LabelsField from 'common/components/Labels/LabelsField';
import { useTestSituationOptions } from 'common/filters/useTestSituationsFilter';
import { AlertMessage } from 'core/components/Alert';
import { FeedbackMessage } from 'core/components/FeedbackMessage';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import { FormContext } from 'core/components/Form';
import Field from 'core/components/Form/Field';
import { StyledHelperText } from 'core/components/Form/Field/styled';
import FormFieldset from 'core/components/Form/FormFieldset';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import FormDropDown from 'core/components/FormDropDown';
import FormTextfield from 'core/components/FormTextfield';
import { Cell, Row } from 'core/components/Grid';
import { FullRowCell } from 'core/components/GridCell';
import IconButton from 'core/components/IconButton';
import PaperContainer from 'core/components/PaperContainer';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import useAppSelector from 'core/hooks/useAppSelector';
import { LabelEntity } from 'lists/models/Label';
import { BLACKLISTS_TYPE } from 'personnel/containers/UsersDetailPage/tabs/BlackListsTab/BlacklistsModal';
import useAuthoritiesOptionsReducer from 'personnel/hooks/useAuthoritiesOptionsReducer';
import useTeamBlacklists from 'personnel/hooks/useTeamBlacklists';
import Athlete from 'personnel/models/Athlete';
import BlacklistSportDisciplines from 'personnel/models/BlacklistSportDisciplines';
import EntityBlacklistedMessage from 'planning/components/EntityBlacklistedMessage';
import PoolsField from 'planning/components/PoolsField';
import useSportsPrioritizedByAthleteReducer from 'planning/hooks/useSportsPrioritizedByAthleteReducer';
import Test from 'planning/models/Test';

import CustomerField from '../../../../../components/CustomerField';
import DebtorField from '../../../../../components/DebtorField';
import FederationsField from '../../../../../components/FederationsField';
import { TestFormData } from '../../../hooks/useTestSchema';

import AthleteField from './AthleteField';
import MissionsIdField from './MissionsIdField';

interface Props {
  handleMissionChange(newValues: Partial<TestFormData>, prevId: number | null): void;
  setFieldValue(name: string, value: any): void;
  hasEnabledTeamAndBackNumber: boolean;
  federationDummyValue?: any;
  entityData?: Test;
}

const BasicInformationFieldset: FC<Props> = ({
  hasEnabledTeamAndBackNumber,
  handleMissionChange,
  entityData,
}) => {
  const { t } = useTranslation();
  const testSituationOptions = useTestSituationOptions();
  const { setValues, values } = useFormikContext<TestFormData>();
  const selectOtherFieldRef = useRef<{ isOther: boolean }>(null);
  const createMissionFromTest = selectOtherFieldRef.current?.isOther;

  const { diffMode, saveOperation } = useContext(FormContext);
  const isBulkEdit = Boolean(diffMode);
  const { missionsId, sportsId: selectedSportsId, sportDisciplinesId, athletesId } = values;

  const user = useAppSelector((state) => state.core.user);
  const clientId = user?.client.id || null;

  const includedAthletes: Athlete[] = useIncludeOptions('athletesId') as Athlete[];
  const includedAthlete =
    includedAthletes.length > 0 && includedAthletes[0].id === athletesId
      ? includedAthletes[0]
      : undefined;
  const [sortComparator, sportsReducer, disciplinesReducer] = useSportsPrioritizedByAthleteReducer(
    includedAthlete || athletesId
  );

  const handleDetailsChange = (name: undefined | string, value: any, values: TestFormData) => {
    if (name === 'sportsId') {
      values.sportDisciplinesId = null;
    }
    if (name) {
      return { ...values, [name]: value };
    }
    return values;
  };

  const prefillSportDiscipline = useFormFieldValue('prefillSportDiscipline');
  const authorityOptionsReducer = useAuthoritiesOptionsReducer();

  const alertMessage: AlertMessage = useMemo(
    () => ({
      value: t('You have blacklisted the Sport Discipline.'),
    }),
    [t]
  );

  const sportDisciplinesBlacklists: BlacklistSportDisciplines[] | undefined = useTeamBlacklists(
    BLACKLISTS_TYPE.SPORT_DISCIPLINES,
    sportDisciplinesId,
    {
      id: 'sportDiscipline',
      message: alertMessage,
    }
  ) as BlacklistSportDisciplines[];

  return (
    <FormFieldset onChange={handleDetailsChange}>
      <PaperContainer title={t('Basic Information')}>
        {!isBulkEdit && (
          <FlexRow>
            <FlexCell flex={1} block>
              <MissionsIdField
                didChange={(data) => handleMissionChange(data, null)}
                selectOtherFieldRef={selectOtherFieldRef}
              />
            </FlexCell>
            {!!missionsId && (
              <FlexCell>
                <IconButton
                  to={entities.mission.urls().detail(missionsId)}
                  icon="link"
                  tooltip={t('Link to Mission')}
                />
              </FlexCell>
            )}
          </FlexRow>
        )}

        <Row>
          {createMissionFromTest && (
            <FullRowCell>
              <Field
                label={t('Mission External ID')}
                component={FormTextfield}
                name="missionsExternalId"
              />
            </FullRowCell>
          )}
          <FullRowCell>
            <Field
              resource={getResourcesUrl('users', {
                rolesId: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.TEST_PLANNER],
                clientsId: clientId,
              })}
              component={ResourceFormDropdown}
              label={t('Assignee')}
              name="assigneesId"
              fast={false}
              single
            />
          </FullRowCell>

          <AthleteField />

          <FullRowCell>
            <FederationsField />
          </FullRowCell>

          {!isBulkEdit && (
            <Cell desktopColumns={4} tabletColumns={8} phoneColumns={4}>
              <Field
                viewMode={
                  !createMissionFromTest
                    ? t('Test Situation can be changed only by changing Mission.')
                    : undefined
                }
                options={testSituationOptions}
                label={t('Test Situation')}
                component={FormDropDown}
                name="testSituationsId"
                single
                fast
              />
            </Cell>
          )}

          <PoolsField entityData={entityData} />

          <FullRowCell>
            <Row>
              <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
                <Field
                  component={ResourceFormDropdown}
                  resource="sports"
                  label={t('Sport')}
                  name="sportsId"
                  single
                  fast={false}
                  resourceReducer={sportsReducer}
                  sortComparator={sortComparator}
                  onChange={(sportsId: number | null) => {
                    if (isBulkEdit && sportsId && sportsId !== values.sportsId) {
                      saveOperation({
                        fieldName: 'sportDisciplinesId',
                        operation: 'remove',
                        type: 'COMMON',
                      });
                    }
                    setValues({
                      ...values,
                      sportDisciplinesId:
                        sportsId !== values.sportsId ? null : values.sportDisciplinesId,
                      sportsId,
                    });
                  }}
                />
              </Cell>
              {(selectedSportsId || isBulkEdit) && (
                <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
                  <Field
                    component={ResourceFormDropdown}
                    label={t('Sport Discipline')}
                    resource="sports/disciplines"
                    name="sportDisciplinesId"
                    fast={false}
                    single
                    resourceReducer={disciplinesReducer}
                    sortComparator={sortComparator}
                  />
                  <EntityBlacklistedMessage
                    entityName={t('Sport Discipline')}
                    blacklists={sportDisciplinesBlacklists}
                  />
                  {prefillSportDiscipline === false && (
                    <FeedbackMessage $type="warning">
                      {t('App pre-fill is disabled in the settings')}
                    </FeedbackMessage>
                  )}
                </Cell>
              )}
            </Row>
          </FullRowCell>

          <FullRowCell>
            <Field name="club" label={t('Club')} component={FormTextfield} />
          </FullRowCell>

          {hasEnabledTeamAndBackNumber && (
            <FullRowCell>
              <Row>
                <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
                  <Field component={FormTextfield} label={t('Team')} name="team" />
                </Cell>
                <Cell desktopColumns={6} tabletColumns={4} phoneColumns={4}>
                  <Field component={FormTextfield} label={t('Back Number')} name="backNumber" />
                </Cell>
              </Row>
            </FullRowCell>
          )}

          <FullRowCell>
            <Field
              component={ResourceFormDropdown}
              label={t('Testing Authority')}
              resource="authorities"
              name="testAuthority"
              onChange={(testAuthorityId: number | null) => {
                setValues({
                  ...values,
                  testAuthority: testAuthorityId,
                  resultAuthority:
                    !values.resultAuthority && testAuthorityId
                      ? testAuthorityId
                      : values.resultAuthority,
                  sampleAuthoritiesId:
                    !values.sampleAuthoritiesId && testAuthorityId
                      ? testAuthorityId
                      : values.sampleAuthoritiesId,
                  initiatorAuthoritiesId:
                    !values.initiatorAuthoritiesId && testAuthorityId
                      ? testAuthorityId
                      : values.initiatorAuthoritiesId,
                  debtorsId:
                    !values.debtorsId && !values.debtorsName && testAuthorityId
                      ? testAuthorityId
                      : values.debtorsId,
                  customersId:
                    !values.customersId && !values.customersName && testAuthorityId
                      ? testAuthorityId
                      : values.customersId,
                });
              }}
              fast={false}
              single
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>

          <FullRowCell>
            <Field
              label={t('Result Management Authority')}
              component={ResourceFormDropdown}
              name="resultAuthority"
              resource="authorities"
              single
              fast
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>

          <FullRowCell>
            <Field
              label={t('Sample Collection Authority')}
              component={ResourceFormDropdown}
              name="sampleAuthoritiesId"
              resource="authorities"
              single
              fast
              resourceReducer={authorityOptionsReducer}
            />
            {!values.sampleAuthoritiesId && (
              <StyledHelperText>
                {t('Leaving the field blank means using the client as the SCA.')}
              </StyledHelperText>
            )}
          </FullRowCell>

          <FullRowCell>
            <Field
              label={t('Doping Control Coordinator')}
              component={ResourceFormDropdown}
              name="initiatorAuthoritiesId"
              resource="authorities"
              single
              fast
              resourceReducer={authorityOptionsReducer}
            />
          </FullRowCell>
          <FullRowCell>
            <CustomerField />
          </FullRowCell>
          <FullRowCell>
            <DebtorField />
          </FullRowCell>

          <FullRowCell>
            <Field label={t('Agent')} component={FormTextfield} name="agent" />
          </FullRowCell>
          <FullRowCell>
            <Field label={t('External ID')} component={FormTextfield} name="externalId" />
          </FullRowCell>

          <LabelsField entityName={LabelEntity.TESTS} />
        </Row>
      </PaperContainer>
    </FormFieldset>
  );
};

export default BasicInformationFieldset;
