import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from 'core/components/Form';
import { Row } from 'core/components/Grid';
import PaperContainer from 'core/components/PaperContainer';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import OfficersFieldset from 'planning/components/OfficersFieldset';
import MembersList from 'planning/components/TeamTab/MembersList';

interface Props {
  hasBlood: boolean;
  limitedAssignmentAllowed?: boolean;
  alreadySavedDcosId?: number[];
  alreadySavedBcosId?: number[];
  alreadySavedChaperonesId?: number[];
  disableAdvanced?: boolean;
}

const AssignmentFieldset: FC<Props> = ({
  hasBlood,
  limitedAssignmentAllowed = false,
  alreadySavedDcosId,
  alreadySavedBcosId,
  alreadySavedChaperonesId,
  disableAdvanced = false,
}) => {
  const { t } = useTranslation();
  const advancedDcoAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const { diffMode: isBulkEdit } = useContext(FormContext);

  return (
    <PaperContainer
      title={advancedDcoAssignmentEnabled && !isBulkEdit ? t('Team') : t('Assignment')}
    >
      {!disableAdvanced && !isBulkEdit ? (
        <MembersList />
      ) : (
        <Row>
          <OfficersFieldset
            disableRemoveAssignment={limitedAssignmentAllowed}
            alreadySavedChaperonesId={alreadySavedChaperonesId || []}
            alreadySavedBcosId={alreadySavedBcosId || []}
            alreadySavedDcosId={alreadySavedDcosId || []}
            hasBlood={hasBlood}
          />
        </Row>
      )}
    </PaperContainer>
  );
};

export default AssignmentFieldset;
