import { push } from 'connected-react-router';
import { FC, UIEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { entities } from 'app/entity';
import DetailSubheader from 'core/components/DetailSubheader';
import Form from 'core/components/Form';
import LoadingPage from 'core/components/LoadingPage';
import Page from 'core/components/Page';
import { MainControl } from 'core/components/PageHeader/Navigation';
import IncludeResourcesProvider from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import usePreviousDatagridParams from 'core/containers/FormDetailPage/usePreviousDatagridParams';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { parse } from 'core/functions/qs';
import useAppSelector from 'core/hooks/useAppSelector';
import usePermission from 'core/hooks/usePermission';
import { useResourceWithCounts } from 'core/hooks/useResource';
import { individualBulkEditTest } from 'planning/actions';
import Settings from 'planning/components/SettingsTab';
import useTestInputMapping from 'planning/containers/TestDetailPage/hooks/useTestInputMapping';
import useTestOutputMapping from 'planning/containers/TestDetailPage/hooks/useTestOutputMapping';
import useTestSchema, {
  TestFormData,
} from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import { DetailIndividualBulk } from 'planning/containers/TestDetailPage/tabs/DetailTab';
import Test from 'planning/models/Test';

import { IncludeOverrideProvider } from '../TestDetailPage/hooks/useIncludeOverride';
import useGetViewMode from '../TestDetailPage/TestForm/useGetViewMode';

import ChangesCollector from './ChangesCollector';
import PageLeavePrompt from './PageLeavePrompt';
import { Container, TestContent, TestHeader } from './styled';

export const MAX_INDIVIDUAL_BULK_EDITED = 20;

const TestIndividualBulkEditPage: FC<RouteComponentProps> = ({ location, match }) => {
  const { t } = useTranslation();
  const search = location.search;
  const selection = useMemo(() => parse(search.replace('?', '')), [search]);
  const dispatch = useDispatch();
  const canEditTestDetails = usePermission('tests:patch[actions:details]');
  // Redirect to list if nothing is selected
  useEffect(() => {
    if (!selection) dispatch(push(entities.test.urls().list));
  }, [selection, dispatch]);
  const mutationPending = useAppSelector((state) => !!state.planning.testBulkEditInProgress);

  const inputMapping = useTestInputMapping('edit', true);
  const outputMapping = useTestOutputMapping({
    disableAdvancedFeatures: true,
    disableTeamMembers: false,
  });
  const testEditSchema = useTestSchema({ disableAdvancedFeatures: true });
  const previousUrlWithParams = usePreviousDatagridParams(entities.test.urls().list);

  const testsResUrl = getResourcesUrl(
    'tests',
    { ...selection, withAllFields: true, limit: MAX_INDIVIDUAL_BULK_EDITED },
    { encodeValuesOnly: true }
  );

  const { data: testsRes } = useResourceWithCounts<Test>(testsResUrl);
  const { data: tests, filtered } = testsRes || {};
  const unsavedChanges = useRef<{ [testsId: number]: TestFormData }>({});
  const didInitialLoad = useRef(false);
  useEffect(() => {
    if (!didInitialLoad.current && Array.isArray(tests) && filtered) {
      filtered > MAX_INDIVIDUAL_BULK_EDITED &&
        toast.info(
          t(
            'The limit of tests edited at once in this view is {{maximum}}. The rest of the tests is not displayed.',
            { maximum: MAX_INDIVIDUAL_BULK_EDITED }
          ) as string
        );
      didInitialLoad.current = true;
    }

    // Clean unsaved changes after we've reloaded tests - we have saved them
    Object.keys(unsavedChanges.current).forEach(
      (testsId) => delete unsavedChanges.current[+testsId]
    );
  }, [tests, filtered, t]);
  const handleSubmit = useCallback(() => {
    if (!tests || Object.keys(unsavedChanges.current).length === 0) {
      toast.info(t('There are no changes to be saved.'));
      return;
    }

    const prevData = Object.fromEntries(
      tests.map((test) => [test.id, outputMapping(inputMapping(test), 'edit', test)])
    );
    const newData = Object.fromEntries(
      Object.entries(unsavedChanges.current).map(([id, data]) => [
        id,
        outputMapping(
          data,
          'edit',
          tests?.find((t) => t.id === +id)
        ),
      ])
    );

    dispatch(individualBulkEditTest(prevData, newData, selection));
  }, [outputMapping, tests, inputMapping, dispatch, t, selection]);

  const subUrls = useMemo(
    () => [
      {
        id: 'detail',
        url: match.url + '/detail' + search,
        path: match.path + '/detail',
        name: t('Detail'),
      },
      ...(canEditTestDetails
        ? [
            {
              id: 'settings',
              url: match.url + '/settings' + search,
              path: match.path + '/settings',
              name: t('Settings'),
            },
          ]
        : []),
    ],
    [match.url, match.path, search, t, canEditTestDetails]
  );

  const mainControls: MainControl[] = [
    {
      permission: 'tests:patch',
      text: mutationPending ? t('Saving...') : t('Save'),
      type: 'submit',
      icon: 'save',
      key: 'save',
      onClick: handleSubmit,
    },
    {
      permission: 'tests:patch',
      text: t('View one form for all tests'),
      onClick: () => {
        dispatch(push(entities.test.urls().bulkEdit(selection)));
      },
      icon: 'view_quilt',
      key: 'switch',
    },
  ];

  /**
   * Imperatively reposition headers with class .sticky-test-header
   *
   * Done imperatively for performance - low overhead.
   * Will work as long as repositioned elements are not added and removed from the DOM on the fly.
   */
  const repositionStickyHeaders = (e: UIEvent<HTMLDivElement>) => {
    // Ignore events that just bubbled up (were not triggered on the container)
    const target = e.target as HTMLDivElement;
    if (target !== e.currentTarget) return;

    const stickyElements = document.querySelectorAll(
      '.sticky-test-header'
    ) as NodeListOf<HTMLDivElement>;
    stickyElements.forEach((el) => {
      el.style.transform = `translate(0, ${target.scrollTop}px)`;
    });
  };

  const getViewMode = useGetViewMode();

  const testForms = useMemo(
    () =>
      tests?.map((test) => {
        const initials = inputMapping(test);
        const viewMode = getViewMode(test.status);
        const athleteName = test.athlete?.fullName || t('Unknown');

        return (
          <IncludeOverrideProvider key={test.id}>
            {([includeOverride]) => (
              <IncludeResourcesProvider
                source={{ ...test, ...includeOverride }}
                mapping={{
                  dcos: 'dcos',
                  bcos: 'bcos',
                  bloodAnalysesId: 'bloodAnalyses',
                  urineAnalysesId: 'urineAnalyses',
                  dbsAnalysesId: 'dbsAnalyses',
                }}
              >
                <div>
                  <TestHeader className="sticky-test-header">
                    <h2>
                      {t(`Test #{{id}} - {{mission}} - {{athlete}}`, {
                        id: test.id,
                        mission: test.mission.code,
                        athlete: athleteName,
                      })}
                    </h2>
                  </TestHeader>

                  <TestContent>
                    <Form<TestFormData>
                      onSubmit={handleSubmit}
                      validationSchema={testEditSchema}
                      initialValues={initials}
                      enableReinitialize={true}
                      id={`testIndividualBulkEditForm-${test.id}`}
                      viewMode={viewMode}
                    >
                      <ChangesCollector reference={unsavedChanges.current} testsId={test.id} />

                      <Switch>
                        <Route
                          key={subUrls[0].url}
                          path={subUrls[0].path}
                          render={() => <DetailIndividualBulk entityData={test} />}
                        />
                        {canEditTestDetails && (
                          <Route
                            key={subUrls[1].url}
                            path={subUrls[1].path}
                            render={() => <Settings displayMode="oneColumn" />}
                          />
                        )}

                        <Redirect to={subUrls[0].url} />
                      </Switch>
                    </Form>
                  </TestContent>
                </div>
              </IncludeResourcesProvider>
            )}
          </IncludeOverrideProvider>
        );
      }),
    [tests, inputMapping, getViewMode, t, handleSubmit, testEditSchema, subUrls, canEditTestDetails]
  );

  if (!tests) {
    return <LoadingPage />;
  }

  return (
    <Page
      backControl={{ text: t('Tests'), to: previousUrlWithParams || entities.test.urls().list }}
      title={t('Bulk edit Tests')}
      mainControls={mainControls}
    >
      <DetailSubheader options={subUrls} />

      <Container onScroll={repositionStickyHeaders}>{testForms}</Container>

      <PageLeavePrompt
        trackUrl={entities.test.urls().individualBulk()}
        reference={unsavedChanges.current}
      />
    </Page>
  );
};

export default TestIndividualBulkEditPage;
