import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  flex: 1;
  gap: 1rem;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`;
