import { useCallback } from 'react';

import Accreditation, { AccreditationsId, AccreditationsRole } from 'app/models/Accreditation';
import User from 'app/models/User';
import BaseModel from 'core/models/Base';
import { ProfileAvailability } from 'personnel/models/ProfileAvailability';
import TeamMember, { TeamMemberStatus } from 'planning/models/TeamMember';

export type TeamEntityData = {
  dcos: User[];
  bcos: User[];
  leadDco: User | null;
  teamMembers?: TeamMember[];
  notifyOfTeamUpdates: boolean;
  showAthleteToDco: boolean;
};

export type TeamMemberData = Omit<TeamMember, keyof BaseModel | 'accreditations' | 'user'> & {
  firstName?: string;
  lastName?: string;
  fullName: string;
  avatarStorageKey: string | null;
  availabilities: ProfileAvailability[];
  labels: string[];
  alreadySavedRoles: AccreditationsRole[] | null;
  allowedRoles: AccreditationsRole[];
};

export type TeamFormData = ReturnType<ReturnType<typeof useTeamInputMapping>>;

export const getAllowedRolesFromAccreditations = (
  accreditations: Accreditation[]
): AccreditationsRole[] => {
  const roles = accreditations.flatMap((accreditation) => {
    const result: AccreditationsRole[] = [];

    if (accreditation.id === AccreditationsId.DCO) {
      result.push(AccreditationsRole.DCO);
    }
    if ([AccreditationsId.BCO, AccreditationsId.PHLEBOTOMIST].includes(accreditation.id)) {
      result.push(AccreditationsRole.BCO);
    }
    if (
      [
        AccreditationsId.DCO,
        AccreditationsId.BCO,
        AccreditationsId.PHLEBOTOMIST,
        AccreditationsId.DCA,
        AccreditationsId.CHAPERONE,
      ].includes(accreditation.id)
    ) {
      result.push(AccreditationsRole.CHAPERONE);
    }

    if (accreditation.id === AccreditationsId.LEAD_DCO) {
      result.push(AccreditationsRole.LEAD_DCO);
    }

    return result;
  });

  return Array.from(new Set(roles));
};

export const mapMember = (member: TeamMember, mode: 'edit' | 'clone' | 'create') => {
  const { user } = member;

  return {
    ...member,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: user.fullName!,
    avatarStorageKey: user.avatarStorageKey || null,
    availabilities: user.availabilities,
    invitations: mode === 'edit' ? member.invitations : [],
    assignmentNotifications: mode === 'edit' ? member.assignmentNotifications : [],
    usersId: user.id,
    labels: user.labels || [],
    // We have to create a copy or the reference is confused with assigned roles by formik
    roles: member.roles ? [...member.roles] : [],
    alreadySavedRoles: member.roles ? [...member.roles] : [],
    allowedRoles: getAllowedRolesFromAccreditations(user.accreditations || []),
    hasSmsContact: user.hasSmsContact,
  };
};

/**
 * Splits assignment fields dcos, bcos, leadDcosId and teamMembers to:
 * - invitedMembers
 * - assignedMembers
 * Has to maintain backward compatibility with the original assignment process
 */
const useTeamInputMapping = (mode: 'edit' | 'clone' | 'create') => {
  return useCallback(
    (data: TeamEntityData) => {
      const assigned: TeamMemberData[] = (data.teamMembers || [])
        .filter((it) => it.status === TeamMemberStatus.CONFIRMED)
        .map((it) => mapMember(it, mode));

      const invited: TeamMemberData[] = (data.teamMembers || [])
        .filter((member) => member.status !== TeamMemberStatus.CONFIRMED)
        .map((it) => mapMember(it, mode));

      return {
        invitedMembers: invited,
        assignedMembers: assigned,
        notifyOfTeamUpdates: data.notifyOfTeamUpdates,
        showAthleteToDco: data.showAthleteToDco,
      };
    },
    [mode]
  );
};

export default useTeamInputMapping;
