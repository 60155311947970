import * as Sentry from '@sentry/browser';
import { FastField as FastFormikField, Field as SlowFormikField, getIn } from 'formik';
import { ComponentType, FC, SyntheticEvent, useContext, useMemo } from 'react';

import DummyCalendarField from 'core/components/DummyCalendarField';
import DummyDropdownField from 'core/components/DummyDropdownField';
import DummyField from 'core/components/DummyField';
import DummyTimeField from 'core/components/DummyTimeField';
import FormCalendarInput from 'core/components/FormCalendarInput';
import FormCalendarRangeInput from 'core/components/FormCalendarRangeInput';
import FormDateTimeField from 'core/components/FormDateTimeField';
import FormDropDown from 'core/components/FormDropDown';
import FormEditorField from 'core/components/FormEditorField';
import FormPhonefield from 'core/components/FormPhonefield';
import DummyPhoneField from 'core/components/FormPhonefield/DummyPhonefield';
import FormRadio from 'core/components/FormRadio';
import FormSwitch from 'core/components/FormSwitch';
import FormTimeInput from 'core/components/FormTimeInput';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { DateTimeFormats } from 'core/i18n/formatDateTime';

import { FormContext } from '..';
import TouchedAnnotation from '../TouchedAnnotation';

import DiffModeIcon from './DiffModeIcon';

/**
 * Required Field component interface
 *
 * @typedef {Object} FieldInterface
 * @param {string} name property name / path in dataset
 * @param {any} value in controlled mode we require value
 * @param {function} onChange input change handler
 */
export interface FieldInterface<V = any> {
  onChange(e: SyntheticEvent<any>): void;
  name: string;
  value: V;
}

/**
 * Field props
 *
 * @typedef {Object} FieldProps
 * @param {void} onChange Handle changes
 * @param {boolean} diffMode enable diff mode (display annotation text if value has been changed) or override context value
 * @param {FC} component rendered input component
 * @param {boolean} fast turn off fast mode (its true by default)
 * @param {string} label field label (* is added automagically when field is required)
 * @param {void} onChange form param name (aka path) could be nested so fieldset.items.0.name
 */
type FieldProps = {
  /** In case of custom field or custom mapping Field -> DummyVersion */
  dummyPlaceholder?: any;
  /** Value used instead of the form value if a dummy field has to be used  */
  dummyValue?: any;
  /** Override context mode property */
  viewMode?: boolean | string; // override viewm mode or put reason
  /** Display changed annotation */
  diffMode?: boolean;
  /** Rendering component */
  component: any;
  /** Formik fast mode (optimize rendering) */
  fast?: boolean;
  label?: string;
  name: string;
  /** Override label asterisk */
  requiredAsterisk?: boolean;
  /** Display secondary value in input */
  displaySelectedSecondary?: boolean;
  /** Called when diff mode resets field value */
  onDiffReset?: () => void;
} & { [key: string]: any };

/**
 * Formik field connection wrapper which adding some more functionality
 * - mark required field with asterisk
 * - enable "changed" annotation
 * - render dummy field when view mode is set
 *
 * @param {FieldProps} params
 */
const Field: FC<FieldProps> = ({
  dummyPlaceholder,
  dummyValue,
  fast,
  component,
  viewMode,
  diffMode,
  label,
  name,
  requiredAsterisk,
  onDiffReset,
  ...rest
}) => {
  const {
    diffMode: contextDiffMode,
    viewMode: formViewMode,
    defaultViewMode,
    requiredMap,
    dense,
    withoutOptionsFields,
    preventAutoComplete,
    customOperations,
  } = useContext(FormContext);

  const diffOptionsDisabled = useMemo(() => {
    if (withoutOptionsFields === true || withoutOptionsFields === false) {
      return withoutOptionsFields;
    }

    return withoutOptionsFields.includes(name);
  }, [name, withoutOptionsFields]);

  const isRemove = customOperations.some(
    (item) => item.fieldName === name && item.operation === 'remove'
  );

  const myViewMode = isRemove
    ? isRemove
    : viewMode !== undefined
      ? viewMode
      : formViewMode && formViewMode.hasOwnProperty(name)
        ? formViewMode[name]
        : formViewMode && defaultViewMode;

  const FormField: ComponentType<any> = myViewMode
    ? dummyPlaceholder || dummyMap(component)
    : component;

  // Warn the developer that our fast={true} default is potentially problematic in some cases
  if (fast === undefined && !myViewMode) {
    if (component === ResourceFormDropdown || component === FormDropDown) {
      // eslint-disable-next-line no-console
      console.warn(`DANGEROUS DEFAULT ON Field. You are using implicit fast=true with DropDown.
      Field ${name} is using DropDown component without using explicit fast param.
      - If your options are static and no prop can change, use explicit fast=true
      - If your options are loaded dynamically or any other prop can change, use explicit fast=false`);
      Sentry.withScope((scope) => {
        scope.setExtra('fieldName', name);
        scope.setExtra('label', label);

        Sentry.captureMessage(
          'Dangerous implicit fast on DropDown Field.',
          Sentry.Severity.Warning
        );
      });
    } else if (rest.onChange) {
      // eslint-disable-next-line no-console
      console.warn(`DANGEROUS DEFAULT ON Field. You are using implicit fast=true with onChange prop.
      Field ${name} is using onChange prop without using explicit fast param.
      - If your onChange does not depend on any variable outside of its scope, use explicit fast=true
      - Else, including if you are not sure what this means, use explicit fast=false`);
      Sentry.withScope((scope) => {
        scope.setExtra('fieldName', name);
        scope.setExtra('label', label);

        Sentry.captureMessage(
          'Dangerous implicit fast on Field with onChange.',
          Sentry.Severity.Warning
        );
      });
    }

    fast = true;
  }

  // Warn the developer that FormDateTimeField in dummy mode must have dummyValue so we can determine timezone
  if (
    myViewMode &&
    component === FormDateTimeField &&
    (!rest.format ||
      rest.format === 'DATETIME_LONG' ||
      rest.format === 'DATETIME_LONG_WITH_SECONDS') &&
    dummyValue === undefined
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `NO DUMMY VALUE ON DATETIME FIELD. DateTime field ${name} should have either:
       - dummyValue defined if there is an attribute that has Luxon DateTime value - otherwise, the displayed timezone is incorrect
       - format defined if there is no relevant attribute with Luxon DateTime value or TZ is overall not available/irrelevant.`
    );
    Sentry.withScope((scope) => {
      scope.setExtra('fieldName', name);
      scope.setExtra('label', label);
      scope.setExtra('myViewMode', {
        isRemove: isRemove,
        viewMode: viewMode,
        formViewMode: formViewMode,
        defaultViewMode: defaultViewMode,
      });
      scope.setExtra('rest.format', rest.format);

      Sentry.captureMessage('No dummy value on DateTime field.', Sentry.Severity.Warning);
    });
  }

  const FormikField = !myViewMode && fast ? FastFormikField : SlowFormikField;
  const reqName = name.replace(/\.[0-9]+\./g, '$').replace(/\[[0-9]+\]/g, '$');
  const isRequired = requiredMap && getIn(requiredMap, reqName);

  const decorateLabel = useMemo(() => {
    if (label) {
      const addAsterisk = requiredAsterisk === undefined ? isRequired : requiredAsterisk;
      return addAsterisk && !label.endsWith('*') ? label + ' *' : label;
    }
    return undefined;
  }, [label, isRequired, requiredAsterisk]);

  const fieldComponent = (
    <FormikField
      tooltip={typeof myViewMode === 'string' ? myViewMode : undefined}
      label={decorateLabel}
      viewMode={!!myViewMode}
      component={FormField}
      dense={dense}
      name={name}
      value={myViewMode && dummyValue ? dummyValue : undefined}
      format={(myViewMode && dummyDateFormatMap(component)) || undefined}
      preventAutoComplete={preventAutoComplete}
      readOnly={FormField === FormEditorField && myViewMode ? true : undefined}
      {...rest}
    />
  );
  const isDiffAllowed = component !== FormSwitch && !name.includes('[');
  const diffModeActive = Boolean(diffMode || (diffMode !== false && contextDiffMode));

  const isMultipleDropdown = Boolean(
    (component === FormDropDown || component === ResourceFormDropdown) && !rest.single
  );

  return (
    <>
      {diffModeActive && isDiffAllowed && !diffOptionsDisabled && (!myViewMode || isRemove) ? (
        <DiffModeIcon name={name} isMultipleDropdown={isMultipleDropdown}>
          {fieldComponent}
        </DiffModeIcon>
      ) : (
        fieldComponent
      )}
      {diffModeActive && (!myViewMode || isRemove) && (
        <TouchedAnnotation name={name} onDiffReset={onDiffReset} />
      )}
    </>
  );
};

/**
 * Get dummy version of field component
 * @param {ComponentType<any>} component
 */
function dummyMap(component: ComponentType<any>) {
  if (
    component === FormCalendarRangeInput ||
    component === FormCalendarInput ||
    component === FormDateTimeField
  ) {
    return DummyCalendarField;
  } else if (component === FormTimeInput) {
    return DummyTimeField;
  } else if (component === FormDropDown || component === FormRadio) {
    return DummyDropdownField;
  } else if (component === ResourceFormDropdown) {
    return ResourceFormDropdown;
  } else if (component === FormPhonefield) {
    return DummyPhoneField;
  } else if (component === FormEditorField) {
    return FormEditorField;
  }
  return DummyField;
}

/**
 * Get default format prop for DummyCalendarField considering passed component type
 *
 * @param {ComponentType<any>} component
 */
function dummyDateFormatMap(component: ComponentType<any>): DateTimeFormats | undefined {
  if (component === FormCalendarInput) {
    return 'DATE_SHORT';
  } else if (component === FormDateTimeField) {
    return 'DATETIME_LONG';
  }
}

export default Field;
