import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatus } from 'planning/models/Order';

export const useGetStatusName = () => {
  const { t } = useTranslation();

  return useCallback(
    (status: OrderStatus) => {
      switch (status) {
        case OrderStatus.NEW:
          return t('New');

        case OrderStatus.SUBMITTED:
          return t('Submitted');

        case OrderStatus.ACCEPTED:
          return t('Accepted');

        case OrderStatus.DECLINED:
          return t('Declined');

        default:
          return '-';
      }
    },
    [t]
  );
};
