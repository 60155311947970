import { FC } from 'react';

import { PopoverContent } from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer/styled';
import { TeamMemberType } from 'planning/components/TeamTab/enums';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import AssignmentNotificationsContent from './AssignmentNotificationsContent';
import InvitationNotificationsContent from './InvitationNotificationsContent';

interface Props {
  user: TeamMemberData & { id: number };
  type: TeamMemberType;
}

const NotificationsPopover: FC<Props> = ({ user, type }) => {
  const Component =
    type === TeamMemberType.INVITED
      ? InvitationNotificationsContent
      : AssignmentNotificationsContent;

  return (
    <PopoverContent>
      <Component user={user} />
    </PopoverContent>
  );
};

export default NotificationsPopover;
