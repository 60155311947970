import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { RouteTabOption } from 'core/components/DetailSubheader/Tabs';
import { DetailModeEnum } from 'core/containers/FormDetailPage';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useGetPermission } from 'core/hooks/usePermission';
import { DetailSettingsTab } from 'planning/components/SettingsTab';
import Team from 'planning/components/TeamTab';

import AuditLogsTab from '../tabs/AuditLogsTab';
import ContentsTab from '../tabs/ContentsTab';
import Detail from '../tabs/Detail';
import FilesTab from '../tabs/FilesTab';
import MissionCommentsTab from '../tabs/MissionCommentsTab';

export default function useMissionTabs(mode: DetailModeEnum) {
  const getPermission = useGetPermission();
  const { path, url } = useRouteMatch();
  const isEdit = mode === 'edit';
  const { t } = useTranslation();

  const canSeeDetails = getPermission('missions:get[actions:details]');
  const canFindFiles = getPermission('missions:files[actions:find]');
  const canSeeTeam = getPermission('missions:get[actions:team]');
  const filesEnabled = useBoolClientOption('enableAttachments');
  const canFindSamples = getPermission('samples:find');
  const canFindTests = getPermission('tests:find');
  const canSeeComments = getPermission('missions:comments');
  const canSeeHistory = getPermission('missions:auditLogs');

  const urls: RouteTabOption[] = [
    {
      id: 'detail',
      path: path + '/detail',
      url: url + '/detail',
      name: t('Detail'),
      component: Detail,
    },
    {
      id: 'team',
      path: path + '/team',
      url: url + '/team',
      name: t('Team'),
      component: Team,
      hidden: !canSeeTeam,
    },
    {
      id: 'settings',
      path: path + '/settings',
      url: url + '/settings',
      name: t('Settings'),
      component: DetailSettingsTab,
      hidden: !canSeeDetails,
    },
    {
      id: 'contents',
      path: path + '/contents',
      url: url + '/contents',
      name: t('Contents'),
      component: ContentsTab,
      disabled: !isEdit,
      title: !isEdit
        ? t('Save Mission before you will be able to see Mission Contents')
        : undefined,
      hidden: !canFindSamples || !canFindTests,
    },
    {
      id: 'attachments',
      component: FilesTab,
      path: path + '/attachments',
      url: url + '/attachments',
      name: t('Attachments'),
      disabled: !isEdit,
      title: !isEdit ? t('Save Mission before you will be able to upload files') : undefined,
      hidden: !(canFindFiles && filesEnabled),
    },
    {
      id: 'history',
      component: AuditLogsTab,
      path: path + '/history',
      url: url + '/history',
      name: t('History')!,
      disabled: !isEdit,
      title: !isEdit ? t('Save Mission before you will be able to access History') : undefined,
      hidden: !canSeeHistory,
    },
    {
      id: 'comments',
      component: MissionCommentsTab,
      path: path + '/comments',
      url: url + '/comments',
      name: t('Comments')!,
      disabled: !isEdit,
      title: !isEdit ? t('Save Mission before you will be able to access comments') : undefined,
      hidden: !canSeeComments,
    },
  ];

  return urls;
}
