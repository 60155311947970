import { GridApi, RowSelectedEvent } from 'ag-grid-community';
import { MutableRefObject, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useGetPermission } from 'core/hooks/usePermission';
import { BULK_ACTIONS_SELECTED_ROWS_LIMIT } from 'core/utils/constant';

import { BulkAction, SelectedRows } from '../props';

interface UseDataGridBulkActions {
  selectedRows: SelectedRows;
  setSelectedRows: (selectedRows: SelectedRows) => void;
  reloadData: () => void;
  agGridApiRef?: MutableRefObject<GridApi<any> | undefined>;
  bulkActions?: BulkAction[];
}

const useDataGridBulkActions = ({
  selectedRows,
  setSelectedRows,
  reloadData,
  agGridApiRef,
  bulkActions,
}: UseDataGridBulkActions) => {
  const evalPermission = useGetPermission();

  const handleBulkActionSuccess = () => {
    if (!agGridApiRef?.current) {
      // eslint-disable-next-line no-console
      console.warn('No reference to the ag grid on bulk action!');
      return;
    }
    reloadData();
  };

  const availableBulkActions = useMemo(() => {
    if (!bulkActions) {
      return undefined;
    }

    let permittedBulkActions = bulkActions.filter(
      (it) => !it.permission || (evalPermission && evalPermission(it.permission))
    );

    // Disable all bulk actions if limit is reached
    const gridSelectedRows = agGridApiRef?.current?.getSelectedRows();
    if ((gridSelectedRows?.length || selectedRows.length) > BULK_ACTIONS_SELECTED_ROWS_LIMIT) {
      permittedBulkActions = permittedBulkActions.map((it) => ({
        ...it,
        disabled: true,
      }));
    }

    return permittedBulkActions.length > 0 ? permittedBulkActions : undefined;
  }, [agGridApiRef, bulkActions, evalPermission, selectedRows.length]);

  const handleRowSelected = useDebouncedCallback((event: RowSelectedEvent) => {
    const selectedNodes = event.api.getSelectedRows();

    let rowCount = 0;
    // Nodes with id are loaded nodes - we don't count the ones that are still loading
    event.api.forEachNode((node) => node.data?.id && rowCount++);

    const selectedRows =
      rowCount !== 0 && selectedNodes.length === rowCount
        ? 'ALL'
        : selectedNodes.map((node) => Number(node.id!));

    setSelectedRows(selectedRows);

    // Refresh select all checkbox
    if (availableBulkActions) {
      // Refresh in timeout to make sure we are not already drawing
      setTimeout(() => event.api.refreshHeader());
    }
  }, 50);

  return {
    handleBulkActionSuccess,
    availableBulkActions,
    handleRowSelected,
  };
};

export default useDataGridBulkActions;
