import MSwitch, { SwitchProps } from '@material/react-switch';
import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

import theme from 'app/theme';

import Button from '../Button';

interface Props extends Omit<SwitchProps, 'ref'> {
  inline?: boolean;
  color?: keyof typeof theme.color;
}

export const Label = styled.label<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? theme.color.textSecondary : 'inherit')};
  cursor: ${(props) => (props.disabled ? 'cursor' : 'pointer')};
`;

export const Wrapper = styled.div<{ inline?: boolean; margin?: string }>`
  ${(props) => (props.margin ? `margin: ${props.margin};` : `margin: 0.625rem 0 0;`)}
  display: flex;
  ${(props) =>
    !props.inline
      ? css`
          flex-direction: column;
        `
      : css`
          padding: 0 0.5rem;

          ${Label} {
            user-select: none;
            margin: 0;
          }
        `}
`;

const MCSwitch: FC<Props> = ({ inline: _1, color: _2, ...rest }: Props) => <MSwitch {...rest} />;

export const StyledMaterialSwitch: FC<Props> = styled(MCSwitch)`
  cursor: pointer;
  & * {
    pointer-events: none !important;
  }
  ${(props) =>
    props.inline &&
    css`
      margin-left: auto;
      align-self: center;
    `}
`;

export const TooltipContainer = styled.div`
  margin-left: auto;
`;

export const SwitchButtonsWrapper = styled.div`
  width: 100%;
`;

export const SwitchButton = styled(Button)<{ $isLeftButton?: boolean }>`
  width: 50%;
  ${(p) =>
    !p.outlined &&
    css`
      border: 1px solid ${theme.color.gray200};
    `}

  ${(p) =>
    p.$isLeftButton
      ? css`
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `}

  ${(p) =>
    p.$isLeftButton &&
    p.outlined &&
    css`
      border-right-width: 3px;
    `}
`;
