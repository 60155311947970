import { FieldProps, useField } from 'formik';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DummyField from 'core/components/DummyField';
import Analysis, { GENE_ANALYSE_CODE } from 'lists/models/Analysis';

import { OtherAnalyseInput } from './OtherBloodAnalyses';

// TODO: Read resources from includes
// TODO: (Hyper ulta turbo version) - share resources with blood analyses field - if loaded
/**
 * Render blood samples list for selected analyses
 */
const BloodSamplesField: FC<FieldProps<number[]> & { analyses: Analysis[] }> = ({
  analyses,
  field,
}) => {
  const { t } = useTranslation();
  const values = useMemo(() => field.value || [], [field.value]);

  const [{ value: others }] = useField('otherBloodAnalyses');

  const otherValues = useMemo(() => (others || []).map((it: OtherAnalyseInput) => it.id), [others]);

  const analysesTypes = useMemo(() => {
    let constainsGene = false;

    const typesSet = [...values, ...otherValues]
      .map((it) => (analyses || []).find((j) => j.id === it))
      .reduce((acc, it) => {
        if (!it) {
          return acc;
        }
        if (it.oneBloodEdtaTube) acc.add('1xEDTA');
        if (it.twoBloodEdtaTubes && it.code !== GENE_ANALYSE_CODE) acc.add('2xEDTA');
        if (it.oneBloodGelTube) acc.add('1xGEL');
        if (it.twoBloodGelTubes) acc.add('2xGEL');
        if (it.oneBloodEdtaTwoGelTubes) acc.add('1xEDTA+2xGEL');

        if (it.code === GENE_ANALYSE_CODE) {
          constainsGene = true;
        }

        return acc;
      }, new Set());

    const typesArray = Array.from(typesSet);

    if (constainsGene) {
      typesArray.push('2xEDTA');
    }

    return typesArray;
  }, [values, otherValues, analyses]);

  return <DummyField value={analysesTypes.join(', ')} label={t('Blood Sample(s)')} />;
};

export default BloodSamplesField;
