import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataCardAction } from 'core/components/DataCard/props';
import { TeamMemberStatus } from 'planning/models/TeamMember';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';

import AddModal from './AddModal';
import CommentsModal from './CommentsModal';
import InvitationsModal from './InvitationsModal';

const useActions = (
  type: TeamMemberType,
  advancedAssignmentEnabled: boolean,
  notRemovableUsersId?: number[]
) => {
  const [togglingInvitation, setTogglingInvitation] = useState<TeamMemberData[]>();
  const [editingComments, setEditingComments] = useState<TeamMemberData>();
  const formik = useFormikContext<TeamFormData>();
  const [adding, setAdding] = useState(false);
  const { t } = useTranslation();

  const dataKey = type === TeamMemberType.INVITED ? 'invitedMembers' : 'assignedMembers';
  const oppositeDataKey = type === TeamMemberType.INVITED ? 'assignedMembers' : 'invitedMembers';
  const formData = formik.values[dataKey];

  const toggleAssignment = (selection: TeamMemberData | TeamMemberData[]) => {
    const current = formik.values[dataKey];
    const removed = new Set(
      Array.isArray(selection) ? selection.map((member) => member.usersId) : [selection.usersId]
    );

    formik.setValues({
      ...formik.values,
      [dataKey]: current.filter((member) => !removed.has(member.usersId)),
      [oppositeDataKey]: [
        ...formik.values[oppositeDataKey],
        ...(Array.isArray(selection) ? selection : [selection]).map((member) => ({
          ...member,
          status:
            type === TeamMemberType.ASSIGNED
              ? TeamMemberStatus.SELECTED
              : TeamMemberStatus.CONFIRMED,
        })),
      ],
    });
  };

  const onRemove = (selection: TeamMemberData | TeamMemberData[]) => {
    const current = formik.values[dataKey];
    const deleted = new Set(
      Array.isArray(selection) ? selection.map((member) => member.usersId) : [selection.usersId]
    );

    formik.setValues({
      ...formik.values,
      [dataKey]: current.filter((member) => !deleted.has(member.usersId)),
    });
  };

  // TODO: this id should be part of form schema bcs is passed by input and processed by output...
  let actions: DataCardAction<TeamMemberData & { id: number }>[] = [
    {
      id: 'assignment',
      bulkIcon: 'person_add',
      title: type === TeamMemberType.INVITED ? t('Assign') : t('Unassign'),
      onClick: toggleAssignment,
    },
    {
      id: 'invitation',
      bulkIcon: 'send',
      title: t('Plan Invitation'),
      onClick: (selection) =>
        setTogglingInvitation(Array.isArray(selection) ? selection : [selection]),
    },
    {
      id: 'comment',
      title: t('Edit Internal Comments'),
      onClick: (selection) =>
        selection && !Array.isArray(selection) && setEditingComments(selection),
    },
  ];

  actions = advancedAssignmentEnabled
    ? actions
    : actions.filter((action) => action.id !== 'invitation');

  const triggers = {
    onToggleInvitations: (member: TeamMemberData) => setTogglingInvitation([member]),
    onEditComments: (member: TeamMemberData) => setEditingComments(member),
    onAdd: () => setAdding(true),
    onRemove,
  };

  const modals = (
    <>
      <AddModal
        notRemovableUsersId={notRemovableUsersId}
        onClose={() => setAdding(false)}
        open={adding}
        type={type}
      />
      <CommentsModal
        index={formData.findIndex((m) => m.usersId === editingComments?.usersId)}
        onClose={() => setEditingComments(undefined)}
        member={editingComments}
        type={type}
      />
      <InvitationsModal
        onClose={() => setTogglingInvitation(undefined)}
        members={togglingInvitation}
        type={type}
      />
    </>
  );

  return [actions, triggers, modals] as const;
};

export default useActions;
