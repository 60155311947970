import { getIn, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AccreditationsRole } from 'app/models/Accreditation';
import { FlexCell } from 'core/components/FlexUtils';
import Switch from 'core/components/Switch';
import Tooltip from 'core/components/Tooltip';

import { TeamMemberType } from '../../enums';
import { TeamFormData, TeamMemberData } from '../../useTeamInputMapping';
import { ErrorIcon } from '../MembersCardItem/cells/RolesCell/styled';

import { RoleItems, ThPlaceholder, Toggle } from './styled';

interface Props {
  /**
   * Disable remove assigned roles
   * that is applied for "already saved" roles
   * so user can add / remove roles until Save button is hit
   */
  disableRemoveAssignment: boolean;
  /**
   * prefix for array field like "teamMembers" or "users"
   * its used for "select all" action
   */
  fieldPrefix: TeamMemberType;
}

/**
 * Walk trough team members and detect if all users have enabled specific role
 */
function areAllSelected(values: TeamMemberData[], role: AccreditationsRole) {
  return !values.find((x) => x.allowedRoles.includes(role) && !x.roles.includes(role));
}

const MembersCardHeader: FC<Props> = ({ fieldPrefix, disableRemoveAssignment }) => {
  const formik = useFormikContext<TeamFormData>();
  const { t } = useTranslation();

  const { setValues, values, initialValues } = formik;
  const usersData = getIn(values, fieldPrefix);

  const selectAll = (role: AccreditationsRole) => (value: boolean) => {
    setValues((formValues: TeamFormData) => {
      const values = { ...formValues };

      values[fieldPrefix] = values[fieldPrefix].map((u: TeamMemberData) => ({
        ...u,
        roles:
          disableRemoveAssignment &&
          initialValues[fieldPrefix].find((w) => w.usersId === u.usersId)?.roles.includes(role)
            ? u.roles
            : value && u.allowedRoles.includes(role)
              ? Array.from(new Set([...u.roles, role]))
              : u.roles.filter((x) => x !== role),
      }));

      return values;
    });
  };

  return (
    <>
      <FlexCell flex={4} minWidth="10rem">
        {/* Avatar with name and availability */}
        <ThPlaceholder> </ThPlaceholder>
      </FlexCell>
      <FlexCell flex={2} minWidth="5rem">
        {/* Status */}
        <ThPlaceholder> </ThPlaceholder>
      </FlexCell>
      <FlexCell flex={6} minWidth="15rem">
        <RoleItems>
          <ErrorIcon></ErrorIcon>
          <div>
            <span>{t('Lead DCO')}</span>
            <Toggle>
              <Switch name="allDCO" id="allDCO" label={''} onChange={() => {}} />
            </Toggle>
          </div>
          <div>
            <span>{t('DCO')}</span>
            <Tooltip
              content={
                areAllSelected(usersData, AccreditationsRole.DCO)
                  ? t('Disable DCO Role for all users. (All with available Accreditation)')
                  : t('Enable DCO Role for all users. (All with available Accreditation)')
              }
            >
              <Toggle>
                <Switch
                  value={areAllSelected(usersData, AccreditationsRole.DCO)}
                  onChange={selectAll(AccreditationsRole.DCO)}
                  name="allDCO"
                  id="allDCO"
                  label={''}
                />
              </Toggle>
            </Tooltip>
          </div>
          <div>
            <span>{t('BCO')}</span>
            <Tooltip
              content={
                areAllSelected(usersData, AccreditationsRole.BCO)
                  ? t('Disable BCO Role for all users. (All with available Accreditation)')
                  : t('Enable BCO Role for all users. (All with available Accreditation)')
              }
            >
              <Toggle>
                <Switch
                  value={areAllSelected(usersData, AccreditationsRole.BCO)}
                  onChange={selectAll(AccreditationsRole.BCO)}
                  name="allBCO"
                  id="allBCO"
                  label={''}
                />
              </Toggle>
            </Tooltip>
          </div>
          <div>
            <span>{t('Chaperone')}</span>
            <Tooltip
              content={
                areAllSelected(usersData, AccreditationsRole.CHAPERONE)
                  ? t('Disable Chaperone Role for all users. (All with available Accreditation)')
                  : t('Enable Chaperone Role for all users. (All with available Accreditation)')
              }
            >
              <Toggle>
                <Switch
                  value={areAllSelected(usersData, AccreditationsRole.CHAPERONE)}
                  onChange={selectAll(AccreditationsRole.CHAPERONE)}
                  name="allCHAPERONE"
                  id="allCHAPERONE"
                  label={''}
                />
              </Toggle>
            </Tooltip>
          </div>
        </RoleItems>
      </FlexCell>
      <FlexCell flex={2}>
        {/* Actions */}
        <ThPlaceholder> </ThPlaceholder>
      </FlexCell>
      <FlexCell flex={1} maxWidth="3rem" minWidth="3rem">
        {/* Actions */}
      </FlexCell>
    </>
  );
};

export default MembersCardHeader;
