import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { put, race, take } from 'redux-saga/effects';

import {
  apiCall,
  beginEditEntity,
  finishEditEntity,
  loadEntitySuccess,
  reloadApiQuery,
} from 'core/actions';
import createDiff from 'core/effects/apiCall/createDiff';
import { transformDateToString } from 'core/effects/apiCall/dateTimeTransformations';
import getResourcesUrl from 'core/functions/getResourcesUrl';
import { stringify } from 'core/functions/qs';
import showUpdatedRecordsMessage from 'core/functions/showUpdatedRecordsMessage';
import { t } from 'core/i18n';
import {
  ApplyMissionToTestsAction,
  BulkEditMissionAction,
  EditMissionAction,
  LoadMissionAction,
  TYPE_APPLY_MISSION_TO_TESTS,
  TYPE_BULK_EDIT_MISSION,
  TYPE_EDIT_MISSION,
  TYPE_LOAD_MISSION,
} from 'planning/actions';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';
import { DISALLOWED_TEST_CHANGE_FIELDS } from 'planning/containers/MissionDetailPage';
import Mission from 'planning/models/Mission';
import TeamMember from 'planning/models/TeamMember';
import Test from 'planning/models/Test';

export function* loadMissionSaga(action: LoadMissionAction) {
  yield put(apiCall(TYPE_LOAD_MISSION, 'GET', `/missions/${action.payload.id}`, {}));
}

export function* editMissionSaga(action: EditMissionAction) {
  const { prevData, formData, id, successCallback } = action.payload;

  yield put(beginEditEntity());

  yield put(
    apiCall(TYPE_EDIT_MISSION, 'PATCH', `/missions/${id}`, formData, {}, true, prevData, [])
  );

  const { success } = yield race({
    success: take(`${TYPE_EDIT_MISSION}_SUCCESS`),
    error: take(`${TYPE_EDIT_MISSION}_ERROR`),
  });

  yield put(finishEditEntity());

  if (success) {
    toast.success(t('Successfully edited'));
    yield put(loadEntitySuccess(success.payload.response, `/missions/${id}`));

    if (formData.assigneesId !== prevData.assigneesId) {
      // Refresh watchers
      yield put(reloadApiQuery(getResourcesUrl(`/missions/${id}/watchers`)));
    }

    if (successCallback) {
      // Invitation status cannot be copied
      const newData = {
        ...formData,
        teamMembers: formData.teamMembers?.map((member: TeamMemberData) => ({
          ...member,
          invitations: [],
        })),
      };
      const oldData = {
        ...prevData,
        teamMembers: prevData.teamMembers?.map((member: TeamMemberData) => ({
          ...member,
          invitations: [],
        })),
      };

      const diff = createDiff(newData, oldData, []);
      successCallback(diff);
    }
  }
}

export function* bulkEditMission(action: BulkEditMissionAction) {
  const {
    selection: params,
    formData,
    initials,
    outputMapping,
    customOperations,
    successCallback,
  } = action.payload;

  const skipOperations = (customOperations || []).filter((it) => it.operation === 'skip');

  if (skipOperations?.length) {
    params.patchOpSkip = skipOperations.map((it) => it.fieldName);
  }

  yield put(
    apiCall(
      TYPE_BULK_EDIT_MISSION,
      'PATCH',
      `/missions/bulk`,
      outputMapping(formData),
      {
        params,
        paramsSerializer: (params) =>
          stringify(params, {
            encodeValuesOnly: true,
          }),
      },
      true,
      outputMapping({
        ...initials,
      }),
      [],
      true,
      customOperations
    )
  );
  const { success } = yield race({
    success: take(`${TYPE_BULK_EDIT_MISSION}_SUCCESS`),
    error: take(`${TYPE_BULK_EDIT_MISSION}_ERROR`),
  });

  if (success) {
    showUpdatedRecordsMessage(success.payload.response);

    yield put({
      type: `${TYPE_BULK_EDIT_MISSION}_SUCCESS`,
      payload: success.payload,
    });
    successCallback && successCallback();
  }
}

/** Clear assignmentNotifications and invitations from teamMembers, so no outbound is sent */
export const clearMemberOutbound = (members: TeamMember[]) =>
  members.map((it) => ({
    ...it,
    assignmentNotifications: [],
    invitations: [],
  }));

export function* applyMissionToTestsSaga(action: ApplyMissionToTestsAction) {
  const { missionsId, customOperations, newData, prevData } = action.payload;

  const endpoint = `/missions/tests?${stringify({
    missionsId,
  })}`;
  yield put(apiCall('load_tests_for_mission', 'GET', endpoint, {}));

  const { success, error } = yield race({
    success: take(`load_tests_for_mission_SUCCESS`),
    error: take(`load_tests_for_mission_ERROR`),
  });

  if (success) {
    const tests: Test[] = success.payload.response.data;

    if (!tests.length) {
      toast.info(t("Mission doesn't have assigned tests"));
      return;
    }

    // Helper to build payload from data with conditional field inclusion and extra fields.
    const buildPayload = (
      data: Partial<Mission>,
      filters: {
        filterBcos: boolean;
        filterDcos: boolean;
        filterChaperones: boolean;
        filterLeadDcosId: boolean;
      },
      extraFields: Record<string, any>
    ) => {
      const { bcos, dcos, chaperones, leadDcosId, ...rest } = data;
      return {
        ...rest,
        ...(!filters.filterDcos && { dcos }),
        ...(!filters.filterBcos && { bcos }),
        ...(!filters.filterChaperones && { chaperones }),
        ...(!filters.filterLeadDcosId && { leadDcosId }),
        ...extraFields,
        ...Object.fromEntries(DISALLOWED_TEST_CHANGE_FIELDS.map((field) => [field, undefined])),
      };
    };

    const prevDateFrom = String(prevData.dateFrom);
    const newDateFrom = String(newData.dateFrom);
    const newPublishAt = DateTime.fromISO(newDateFrom, { setZone: true }).minus({ day: 1 });

    // When bulk edit is performed, these fields are null, so we are able to make clear operation,
    // it is done by sending empty array in new values resulting to removing all dcos/bcos/chaperones
    // We must remove these when it's null as it wrongly clear these values when they are not set
    const filters = {
      filterBcos: newData?.bcos === null,
      filterDcos: newData?.dcos === null,
      filterChaperones: newData?.chaperones === null,
      filterLeadDcosId: newData?.leadDcosId === null,
    };

    const extraNext = {
      ...(newData.teamMembers && { teamMembers: clearMemberOutbound(newData.teamMembers) }),
      publishAt:
        newDateFrom !== prevDateFrom ? transformDateToString(newPublishAt, 'DATETIME') : null,
    };

    const extraPrev = {
      publishAt: null,
    };

    const next = buildPayload(newData, filters, extraNext);
    const prev = buildPayload(prevData, filters, extraPrev);

    // if there is no federation => ignore this change bcs tests has required federation but missions not
    // See: https://cannypack.atlassian.net/browse/MODFE-734 for motivation
    if (!next.federationsId && !next.federationsName) {
      delete next.federationsId;
      delete next.federationsName;
      delete prev.federationsId;
      delete prev.federationsName;
    }

    const params: { id: number[]; patchOpSkip?: string[] } = {
      id: tests.map((i) => i.id),
    };

    const skipOperations = (customOperations || []).filter((it) => it.operation === 'skip');

    if (skipOperations?.length) {
      params.patchOpSkip = skipOperations.map((it) => it.fieldName);
    }

    yield put(
      apiCall(
        TYPE_APPLY_MISSION_TO_TESTS,
        'PATCH',
        `/tests/bulk/`,
        next,
        {
          params,
          paramsSerializer: (params) =>
            stringify(params, {
              encodeValuesOnly: true,
            }),
        },
        true,
        prev,
        []
      )
    );

    const { ok } = yield race({
      ok: take(`${TYPE_APPLY_MISSION_TO_TESTS}_SUCCESS`),
      error: take(`${TYPE_APPLY_MISSION_TO_TESTS}_ERROR`),
    });

    if (ok) {
      toast.success(
        t('{{count}} associated test(s) were updated successfully', {
          count: tests.length,
        })
      );
    }
  } else if (error) {
    toast.error(t('Loading associated tests failed'));
  }

  action.payload.onSettled?.();
}
