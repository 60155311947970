import Tippy, { TippyProps } from '@tippyjs/react';
import { ICellRendererParams } from 'ag-grid-community';
import { PropsWithChildren, ReactNode } from 'react';

import theme from 'app/theme';
import { FlexColumn, FlexRow } from 'core/components/FlexUtils';
import Link from 'core/components/Link';
import Text from 'core/components/Text';
import 'tippy.js/themes/light.css';

import { PopoverContent, PopoverTarget, PopoverTitle } from './styled';

type Props<T, V> = Omit<TippyProps, 'content'> & {
  options?: PopoverOptionsType;
  content?: (data?: T) => ReactNode;
  params?: ICellRendererParams<T, V>;
  children?: ReactNode;
};
export type PopoverHeaderType = {
  title?: ReactNode;
  link?: string;
  customIcon?: ReactNode;
  $titleColor?: string;
};
export type PopoverBlockType =
  | {
      label: ReactNode;
      value: ReactNode;
      link?: string;
      customIcon?: ReactNode;
      noTruncate?: boolean;
    }
  | { customElement: ReactNode };

export type PopoverOptionsType = {
  items?: PopoverBlockType[];
} & PopoverHeaderType;

export const defaultTippyOptions = {
  arrow: false,
  placement: 'right',
  interactiveBorder: 0,
  animation: 'scale-subtle',
  theme: 'light',
} as const;

const PopoverCellRenderer = <T, V>({
  children,
  content,
  options,
  params,
  ...props
}: PropsWithChildren<Props<T, V>>) => {
  return (
    <Tippy
      interactive
      appendTo={document.body}
      content={
        options?.title || options?.items ? (
          <PopoverContent>
            <PopoverHeader
              title={options.title}
              link={options.link}
              customIcon={options.customIcon}
              $titleColor={options.$titleColor}
            />
            {options && options?.items && options?.items.length > 0 && (
              <>{options?.items?.map((item, index) => <PopoverBlock key={index} {...item} />)}</>
            )}
          </PopoverContent>
        ) : content ? (
          content(params?.data)
        ) : null
      }
      {...defaultTippyOptions}
      {...props}
    >
      <PopoverTarget>{children}</PopoverTarget>
    </Tippy>
  );
};

export const PopoverBlock = (props: PopoverBlockType) => {
  if ('customElement' in props) return props.customElement;

  if (!props.value) return null;

  const textElement = (
    <Text $color={theme.color.baseFontColor} $noTruncate={props.noTruncate}>
      {props.value}
    </Text>
  );

  return (
    <FlexColumn verticalAlign="start" spacing="0" marginTop="0.5rem">
      <FlexRow spacing="0rem">
        <PopoverTitle $color={theme.color.textSecondary}>{props.label}</PopoverTitle>
      </FlexRow>
      <FlexRow horizontalAlign="space-between" fullWidth>
        {props.link ? (
          <Link to={props.link} text={textElement} icon={props.customIcon} />
        ) : (
          textElement
        )}
      </FlexRow>
    </FlexColumn>
  );
};

export const PopoverHeader = (props: PopoverHeaderType) => {
  if (!props.title) return null;

  const titleElement = (
    <Text color={props.$titleColor} $fontSize="1rem">
      {props.title}
    </Text>
  );

  return (
    <FlexRow horizontalAlign="space-between" spacing="0rem">
      {props.link ? (
        <Link to={props.link} icon={props.customIcon} text={titleElement} />
      ) : (
        titleElement
      )}
    </FlexRow>
  );
};

export default PopoverCellRenderer;
