import styled from 'styled-components/macro';

import theme from 'app/theme';

interface Props {
  /**
   * Type of the feedback. Defaults to error.
   */
  $type?: keyof typeof theme.color;
  /**
   * Size of the feedback text. Defaults to 0.75rem.
   */
  $size?: string;
  /**
   * Font weight of the feedback text. Defaults to normal.
   */
  $weight?: string;
}

/**
 * Text conveying feedback to the user - most often used under form fields.
 */
export const FeedbackMessage = styled.div<Props>`
  color: ${({ $type }) => theme.color[$type || 'error']};
  font-size: ${({ $size }) => $size || '0.75rem'};
  font-weight: ${({ $weight }) => $weight || 'normal'};
  margin-left: 0.375rem;
  padding-top: 0.2rem;

  &:first-letter {
    text-transform: uppercase;
  }
  & > a {
    color: ${({ $type }) => theme.color[$type || 'error']};
    text-decoration: underline;
  }
`;
