import { useFormikContext } from 'formik';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AccreditationsRole } from 'app/models/Accreditation';
import DataCard from 'core/components/DataCard';
import { FormContext } from 'core/components/Form';

import { TeamMemberType } from '../enums';
import { TeamFormData, TeamMemberData } from '../useTeamInputMapping';

import MembersCardFooter from './MembersCardFooter';
import MembersCardHeader from './MembersCardHeader';
import MembersCardItem from './MembersCardItem';
import useActions from './useActions';

interface Props {
  availabilities: { [usersId: number]: string };
  advancedAssignmentEnabled: boolean;
  disableRemoveAssignment?: boolean;
  type: TeamMemberType;
  showStatus: boolean;
}

const MembersCard: FC<Props> = ({
  disableRemoveAssignment = false,
  advancedAssignmentEnabled,
  availabilities,
  showStatus,
  type,
}) => {
  const { initialValues, values } = useFormikContext<
    TeamFormData & { dateRange: { from: Date; to: Date } | null }
  >();
  const { t } = useTranslation();
  const data = values[type];

  const alreadySavedUsersId = useMemo(
    () => (initialValues.assignedMembers || []).map((i) => i.usersId),
    [initialValues.assignedMembers]
  );

  const { viewMode, defaultViewMode } = useContext(FormContext);
  const isTypeInvited = type === TeamMemberType.INVITED;

  const isViewMode = !!viewMode?.hasOwnProperty(type)
    ? !!viewMode[type]
    : !!viewMode && defaultViewMode;

  const actionsDisabled = isViewMode && !disableRemoveAssignment;
  const [sortedData, idIndexMap] = useMemo(() => {
    const teamMembersMap = new Map<number, number>();

    const sortedData = data
      .map((m, index) => {
        teamMembersMap.set(m.usersId, index);
        return { ...m, id: m.usersId };
      })
      .sort((b, a) =>
        b.fullName ? b.fullName.localeCompare(a.fullName, undefined, { sensitivity: 'variant' }) : 0
      );

    return [sortedData, teamMembersMap];
  }, [data]);

  const [actions, triggers, modals] = useActions(
    type,
    advancedAssignmentEnabled,
    disableRemoveAssignment ? alreadySavedUsersId : undefined
  );

  const isLeadDCOSaved = !!initialValues.assignedMembers
    .concat(initialValues.invitedMembers)
    .find((u) => u.alreadySavedRoles?.includes(AccreditationsRole.LEAD_DCO));

  const triggerRemoveIfCan = useCallback(
    (selection: TeamMemberData | TeamMemberData[]) => {
      if (disableRemoveAssignment && alreadySavedUsersId) {
        const selectedUsersId = Array.isArray(selection)
          ? selection.map((it) => it.usersId)
          : [selection.usersId];

        if (selectedUsersId.some((it) => alreadySavedUsersId.indexOf(it) >= 0)) {
          toast.error(t('Already saved team members can not be removed') as string);
          return;
        } else {
          triggers.onRemove(selection);
        }
      }

      triggers.onRemove(selection);
    },
    [alreadySavedUsersId, disableRemoveAssignment, triggers, t]
  );

  return (
    <>
      <DataCard
        title={isTypeInvited ? t('Invited') : t('Assigned')}
        onRemove={triggerRemoveIfCan} // this should be more precise but currently we do not handle "readonly mode"
        header={
          sortedData.length > 0 && (
            <MembersCardHeader
              disableRemoveAssignment={disableRemoveAssignment}
              fieldPrefix={type}
            />
          )
        }
        actions={isViewMode ? [] : actions}
        footer={
          <MembersCardFooter
            actionsDisabled={actionsDisabled}
            isViewMode={isViewMode}
            onAdd={triggers.onAdd}
            type={type}
          />
        }
        items={sortedData}
        type="field"
      >
        {(user) => (
          <MembersCardItem
            disableChangeLeadDco={disableRemoveAssignment && isLeadDCOSaved}
            advancedAssignmentEnabled={advancedAssignmentEnabled}
            actionsDisabled={actionsDisabled}
            index={idIndexMap.get(user.id)!}
            availabilities={availabilities}
            showStatus={showStatus}
            isViewMode={isViewMode}
            triggers={triggers}
            fieldPrefix={type}
            type={type}
            user={user}
            disableRemoveRoles={
              disableRemoveAssignment && alreadySavedUsersId.includes(user.usersId)
                ? user.alreadySavedRoles || undefined
                : []
            }
          />
        )}
      </DataCard>

      {modals}
    </>
  );
};

export default MembersCard;
