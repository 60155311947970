import { useFormikContext } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { entities } from 'app/entity';
import {
  AccreditationsId,
  bcoAccreditations,
  chaperoneAccreditations,
  dcoAccreditations,
} from 'app/models/Accreditation';
import Button from 'core/components/Button';
import ContextMenu from 'core/components/ContextMenu';
import { FlexCell, FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';
import { Item as MenuItem } from 'core/components/Menu';
import { stringify } from 'core/functions/qs';
import { useBoolClientOption } from 'core/hooks/useClientOption';

import { TeamMemberType } from '../enums';
import { TeamFormData, TeamMemberData } from '../useTeamInputMapping';

interface Props {
  actionsDisabled: boolean;
  type: TeamMemberType;
  isViewMode: boolean;
  onAdd: () => void;
}

const MembersCardFooter: FC<Props> = ({ isViewMode, type, actionsDisabled, onAdd }) => {
  const { values } = useFormikContext<
    TeamFormData & { dateRange: { from: Date; to: Date } | null }
  >();

  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const { t } = useTranslation();

  const { dateRange, invitedMembers, assignedMembers } = values;

  const dateFrom = dateRange?.from;
  const dateTo = dateRange?.to;

  const getDCOAgendaConditions = useCallback(
    (accreditation?: number[], dcos?: TeamMemberData[]) =>
      stringify({
        filters: {
          ...(accreditation && { accreditation }),
          ...(dcos && { dcos: dcos.map((d) => d.usersId) }),
        },
        range:
          dateFrom && dateTo
            ? { from: dateFrom.toISOString(), to: dateTo.toISOString() }
            : undefined,
      }),
    [dateFrom, dateTo]
  );

  const agendaMenuItems: MenuItem[] = useMemo(() => {
    const items: MenuItem[] = [];
    if (
      (type === TeamMemberType.INVITED && invitedMembers.length > 0) ||
      (type === TeamMemberType.ASSIGNED && assignedMembers.length > 0)
    ) {
      items.push({
        key: `${type}-members`,
        text: t('{{type}} Members', {
          type: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
        }),
        icon: 'launch',
        onClick: () =>
          window.open(
            `${entities.agenda.urls().list}?${getDCOAgendaConditions(
              undefined,
              type === TeamMemberType.INVITED ? invitedMembers : assignedMembers
            )}`,
            '_blank'
          ),
      });
    }

    const getCommonItem = (key: string, text: string, accreditations: AccreditationsId[]) => ({
      key,
      text,
      icon: 'launch',
      onClick: () =>
        window.open(
          `${entities.agenda.urls().list}?${getDCOAgendaConditions(accreditations)}`,
          '_blank'
        ),
    });

    const commonItems: MenuItem[] = [
      getCommonItem('DCO', t('DCO'), dcoAccreditations),
      getCommonItem('BCO', t('BCO'), bcoAccreditations),
      getCommonItem('Chaperone', t('Chaperone'), chaperoneAccreditations),
    ];
    return [...items, ...commonItems];
  }, [assignedMembers, getDCOAgendaConditions, invitedMembers, t, type]);

  return (
    <FlexRow horizontalAlign="space-between">
      {!actionsDisabled && (
        <FlexCell>
          <Button
            data-cy={type === TeamMemberType.INVITED ? 'addInvitation' : 'addAssignment'}
            icon={<MaterialIcon icon="add" />}
            onClick={onAdd}
            text={t('Add')}
            type="button"
          />
        </FlexCell>
      )}

      {!isViewMode && availabilitiesEnabled && (
        <FlexCell>
          <ContextMenu menuItems={agendaMenuItems} menuId={`${type}-agenda-menu`}>
            <Button type="button" text={t('Agenda')} icon={<MaterialIcon icon="date_range" />} />
          </ContextMenu>
        </FlexCell>
      )}
    </FlexRow>
  );
};

export default MembersCardFooter;
