import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from 'core/components/IconButton';
import Tooltip from 'core/components/Tooltip';
import { TeamMemberType } from 'planning/components/TeamTab/enums';
import { TeamMemberData } from 'planning/components/TeamTab/useTeamInputMapping';

import useActions from '../../../useActions';

import NotificationsPopover from './NotificationsPopover';
import { Wrapper } from './styled';

interface Props {
  availabilities: { [usersId: number]: string };
  triggers: ReturnType<typeof useActions>['1'];
  user: TeamMemberData & { id: number };
  advancedAssignmentEnabled: boolean;
  actionsDisabled: boolean;
  type: TeamMemberType;
  viewMode: boolean;
}

enum SendingStatus {
  NONE = 0,
  SENT = 1,
  SENDING = 2,
}

const ActionsCell: FC<Props> = ({
  advancedAssignmentEnabled,
  actionsDisabled,
  availabilities,
  triggers,
  user,
  type,
}) => {
  const { t } = useTranslation();

  const typeNotificationKey =
    type === TeamMemberType.INVITED ? 'invitations' : 'assignmentNotifications';

  const sendingStatus = user[typeNotificationKey].reduce((acc, i) => {
    return Math.max(acc, i.createdAt ? SendingStatus.SENT : SendingStatus.SENDING);
  }, SendingStatus.NONE);

  const info = availabilities[user.usersId] ? [availabilities[user.usersId]] : [];
  info.push(...user.labels);

  const doesHaveInvitationsOrNotifications =
    user.invitations.length > 0 || user.assignmentNotifications.length > 0;

  return (
    <Wrapper>
      {advancedAssignmentEnabled && (
        <Tooltip
          theme="light"
          interactive
          content={
            doesHaveInvitationsOrNotifications ? (
              <NotificationsPopover type={type} user={user} />
            ) : undefined
          }
        >
          <IconButton
            icon="send"
            tooltip={
              doesHaveInvitationsOrNotifications
                ? undefined
                : t('Plan {{notification}} via email or SMS', {
                    notification: type === TeamMemberType.INVITED ? 'invitation' : 'notification',
                  })
            }
            onClick={() => triggers.onToggleInvitations(user)}
            color={
              sendingStatus === SendingStatus.SENDING
                ? 'warning'
                : sendingStatus === SendingStatus.SENT
                  ? 'success'
                  : 'gray300'
            }
            disabled={actionsDisabled}
          />
        </Tooltip>
      )}

      <IconButton
        tooltip={user.internalComment ? user.internalComment : t('Add internal comment')}
        icon={user.internalComment ? 'speaker_notes' : 'chat_bubble_outline'}
        onClick={() => triggers.onEditComments(user)}
        disabled={actionsDisabled}
      />
    </Wrapper>
  );
};

export default ActionsCell;
