import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import FormDropDown from 'core/components/FormDropDown';
import { FullRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { useIncludeOptions } from 'core/components/ResourceFormDropdown/IncludeResourcesProvider';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import { LabelEntity } from 'lists/models/Label';

const LabelsField: FC<{ fieldName?: string; entityName: LabelEntity }> = ({
  fieldName = 'labels',
  entityName,
}) => {
  const { t } = useTranslation();
  const labelsEnabled = useBoolClientOption('enableLabels');
  const canSeeLabels = usePermission('labels:find');
  // labelssId => this will give us labels include array
  const labelsInclude = useIncludeOptions('labelssId');
  const labelsOptions = labelsInclude
    .filter((it) => it.name)
    .map((it) => ({ id: it.labelsId, name: it.name }));

  if (!labelsEnabled) {
    return null;
  }

  if (!canSeeLabels) {
    return (
      <FullRowCell>
        <Field
          label={t('Labels')}
          name="labels"
          options={labelsOptions}
          component={FormDropDown}
          fast={false}
          single
        />
      </FullRowCell>
    );
  }

  const labelsResourceUrl = getResourceUrl('labels', {
    entityName,
  });

  return (
    <FullRowCell>
      <Field
        label={t('Labels')}
        component={ResourceFormDropdown}
        name={fieldName}
        resource={labelsResourceUrl}
        fast={false}
      />
    </FullRowCell>
  );
};

export default LabelsField;
