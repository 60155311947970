import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import useFormFieldValue from 'core/components/Form/useFormFieldValue';
import { ExplanationWrapper } from 'core/components/FormStyles';
import FormSwitch from 'core/components/FormSwitch';
import { Row } from 'core/components/Grid';
import { FullRowCell, HalfRowCell } from 'core/components/GridCell';
import PaperContainer from 'core/components/PaperContainer';
import { useDetail } from 'core/containers/FormDetailPage/DetailContext';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { TestFormData } from 'planning/containers/TestDetailPage/hooks/useTestSchema';
import Test from 'planning/models/Test';

import useDisableRemoveAssignment from '../InlineAssignmentModal/getIsDisabledRemoveAssignment';

import { TeamMemberType } from './enums';
import MembersCard from './MembersCard';
import useUserAvailabilities from './MembersCard/useUserAvailabilities';

const TeamTab: FC = () => {
  const advancedAssignmentEnabled = useBoolClientOption('enableAdvancedDcoAssignment');
  const availabilitiesEnabled = useBoolClientOption('enableAvailabilities');
  const { entityData: initialData } = useDetail<Test, TestFormData>();
  const assigned = useFormFieldValue('assignedMembers');
  const invited = useFormFieldValue('invitedMembers');
  const { t } = useTranslation();

  const disableRemoveAssignment = useDisableRemoveAssignment(initialData);
  const members = useMemo(() => [...invited, ...assigned], [invited, assigned]);

  const availabilities = useUserAvailabilities(
    availabilitiesEnabled ? members : [],
    initialData?.dateFrom,
    initialData?.dateTo
  );

  return (
    <Row>
      {advancedAssignmentEnabled && (
        <FullRowCell>
          <MembersCard
            advancedAssignmentEnabled={advancedAssignmentEnabled}
            disableRemoveAssignment={disableRemoveAssignment}
            availabilities={availabilities}
            type={TeamMemberType.INVITED}
            showStatus
          />
        </FullRowCell>
      )}

      <FullRowCell>
        <MembersCard
          advancedAssignmentEnabled={advancedAssignmentEnabled}
          disableRemoveAssignment={disableRemoveAssignment}
          showStatus={advancedAssignmentEnabled}
          availabilities={availabilities}
          type={TeamMemberType.ASSIGNED}
        />
      </FullRowCell>

      <HalfRowCell>
        <PaperContainer title={t('Notification')}>
          <Field
            label={t('Notify me of status updates')}
            name="notifyOfTeamUpdates"
            component={FormSwitch}
            inline
          />

          <ExplanationWrapper>
            {t(
              'The assignee will be notified by e-mail when invited team members react to the invitation.'
            )}
          </ExplanationWrapper>
        </PaperContainer>
      </HalfRowCell>
      <HalfRowCell>
        {advancedAssignmentEnabled && (
          <PaperContainer title={t('Visibility')}>
            <Field
              label={t('Show athlete and gender to invited DCO')}
              name="showAthleteToDco"
              component={FormSwitch}
              inline
            />

            <ExplanationWrapper>
              {t('The invited DCO will be able to see the athlete and gender in the Backoffice.')}
            </ExplanationWrapper>
          </PaperContainer>
        )}
      </HalfRowCell>
    </Row>
  );
};

export default TeamTab;
