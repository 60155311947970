import ActiveModel from 'core/models/Active';
import Base from 'core/models/Base';
import { Client } from 'core/models/Client';
import { Role } from 'core/models/Role';
import TimeZone from 'core/models/TimeZone';

export type FirstName = string;
export type MiddleName = string | null;
export type LastName = string;
export type FullName = string | null;
export type Gender = null | 'm' | 'f' | 'x';
export type Email = string;
export type Phone = string | null;
export type RolesId = number;
export type ClientsId = number;
export type Language = string | null;
export type LocalesId = number | null;
export type TimeZonesId = number | null;

export enum GenderEnum {
  MALE = 'm',
  FEMALE = 'f',
  OTHER = 'x',
}

export enum UserStatus {
  ACTIVE = 1,
  AWAITING = 2,
  INACTIVE = 3,
}

export interface UserAddress {
  street: null | string;
  state: null | string;
  city: null | string;
  countriesId: null | number;
  zip: null | string;
  address: null | string;
  type: string;
  id: null | number;
}

export interface UserSettings {
  translationsLanguages?: string[] | null;
  seenReleaseNotes?: string[] | null;
}

export interface UserContact extends Base {
  value: null | string;
  private: boolean;
  type: string; // fax, mobile, email
}

export default interface CoreUser extends ActiveModel {
  firstName: FirstName;
  middleName: MiddleName;
  lastName: LastName;
  fullName: FullName;
  gender: Gender;
  email: Email;
  externalId: null | string;
  phone: Phone;
  rolesId: RolesId;
  clientsId: ClientsId;
  language: Language;
  localesId?: LocalesId;
  timeZonesId?: TimeZonesId;
  status: UserStatus;
  client: Client;
  timeZone?: TimeZone;
  role: Role;
  addresses: UserAddress[];
  contacts: UserContact[];
  avatarStorageKey?: string;
  avatarStorageKeyBig?: string;
  hasTwoFactor: boolean;
  mustChangePassword: boolean;
  settings: UserSettings;
}
