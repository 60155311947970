import { ColDef } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import { useAthleteColumn } from 'common/columns/useAthleteColumn';
import { sampleTypesToGrid, useSampleTypeColumn } from 'common/columns/useSampleTypeColumn';
import { FlexRow } from 'core/components/FlexUtils';
import MaterialIcon from 'core/components/MaterialIcon';
import Spacer from 'core/components/Spacer';
import PopoverCellRenderer from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer';
import {
  PopoverContent,
  PopoverDescriptionItem,
  PopoverTitle,
} from 'core/containers/DataGrid/CellRenderers/PopoverCellRenderer/styled';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import { useFormatDateTime } from 'core/i18n/useFormatDateTime';
import Analysis from 'lists/models/Analysis';
import Coc from 'planning/models/Coc';
import { MissionDatagrid } from 'planning/models/Mission';
import Sample from 'planning/models/Sample';

const sampleTypeMapUrine = ['oneUrineTube'];
const sampleTypeMapBlood = [
  'oneBloodEdtaTube',
  'twoBloodEdtaTubes',
  'twoBloodGelTubes',
  'oneBloodEdtaTwoGelTubes',
];
const sampleTypeMapDbs = ['driedBloodSpots'];

const useTableDefinitions = () => {
  const { t } = useTranslation();
  const formatDateTime = useFormatDateTime();
  const getAthleteColumn = useAthleteColumn();
  const showAnalysesToAssignedDco = useBoolClientOption('showAnalysesToAssignedDco');

  const samplesMap = useMemo(
    () =>
      ({
        oneUrineTube: t('Urine'),
        oneBloodEdtaTube: t('1xEDTA'),
        twoBloodEdtaTubes: t('2xEDTA'),
        twoBloodGelTubes: t('2xGEL'),
        oneBloodEdtaTwoGelTubes: t('1xEDTA+2xGEL'),
        driedBloodSpots: t('DBS'),
      }) as const,
    [t]
  );

  const getTestsTooltip = useCallback(
    (data?: MissionDatagrid) => {
      const analyses = [
        ...(data?.urineAnalyses || []),
        ...(data?.bloodAnalyses || []),
        ...(data?.dbsAnalyses || []),
      ];
      const analysesTemp = Object.entries(samplesMap).map(([key, value]) => {
        const shortNames = analyses
          .map(
            (item) =>
              item[key as keyof Analysis] && (
                <PopoverDescriptionItem key={item.id}>{item.shortName}</PopoverDescriptionItem>
              )
          )
          .filter(Boolean);

        if (!shortNames.length) return undefined;

        return (
          <>
            <PopoverTitle>{value}</PopoverTitle>
            {shortNames}
          </>
        );
      });

      return (
        <PopoverContent>
          <>{analysesTemp}</>
        </PopoverContent>
      );
    },
    [samplesMap]
  );

  const sampleTypeColumn = useSampleTypeColumn();
  const sampleTypeIcons = useMemo(() => {
    return [
      {
        id: 'urine',
        content: (data: { urineAnalyses: Analysis[] }) => {
          const filteredSampleTypeMapper = sampleTypeMapUrine.filter((map) =>
            data.urineAnalyses?.some((item: Analysis) => item[map as keyof Analysis])
          );

          return filteredSampleTypeMapper.length > 0
            ? filteredSampleTypeMapper
                .map((i) => samplesMap[i as keyof typeof samplesMap])
                .join(', ')
            : t('No Urine analyses');
        },
        color: (data: { urineAnalyses: Analysis[] }) =>
          sampleTypeMapUrine.filter((map) =>
            data.urineAnalyses?.some((item: Analysis) => item[map as keyof Analysis])
          ).length > 0
            ? theme.color.status.orange
            : theme.color.orange600,
        size: 1.25,
        name: 'urine',
      },
      {
        id: 'blood',
        content: (data: { bloodAnalyses: Analysis[] }) => {
          const filteredSampleTypeMapper = sampleTypeMapBlood.filter((map) =>
            data.bloodAnalyses?.some((item: Analysis) => item[map as keyof Analysis])
          );

          return filteredSampleTypeMapper.length > 0
            ? filteredSampleTypeMapper
                .map((i) => samplesMap[i as keyof typeof samplesMap])
                .join(', ')
            : t('No Blood analyses');
        },
        color: (data: { bloodAnalyses: Analysis[] }) =>
          sampleTypeMapBlood.filter((map) =>
            data.bloodAnalyses?.some((item: Analysis) => item[map as keyof Analysis])
          ).length > 0
            ? theme.color.status.red
            : theme.color.red600,
        size: 1.25,
        name: 'blood',
      },
      {
        id: 'dbs',
        content: (data: { dbsAnalyses: Analysis[] }) => {
          const filteredSampleTypeMapper = sampleTypeMapDbs.filter((map) =>
            data.dbsAnalyses?.some((item: Analysis) => item[map as keyof Analysis])
          );

          return filteredSampleTypeMapper.length > 0
            ? filteredSampleTypeMapper
                .map((i) => samplesMap[i as keyof typeof samplesMap])
                .join(', ')
            : t('No DBS analyses');
        },
        color: (data: { dbsAnalyses: Analysis[] }) =>
          sampleTypeMapDbs.filter((map) =>
            data.dbsAnalyses?.some((item: Analysis) => item[map as keyof Analysis])
          ).length > 0
            ? theme.color.burgundy
            : theme.color.burgundy600,
        size: 1.25,
        name: 'dbs',
      },
    ];
  }, [t, samplesMap]);

  return useMemo(
    (): Record<string, () => { title: string; columns: ColDef[] }> =>
      ({
        tests: () => ({
          title: t('Tests'),
          columns: [
            {
              headerName: t('Test ID'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 80,
            },
            getAthleteColumn(),
            {
              ...sampleTypeColumn,
              field: 'sampleType',
              cellRendererParams: { icons: sampleTypeIcons },
              cellRenderer: (params: any) =>
                showAnalysesToAssignedDco ? (
                  <PopoverCellRenderer<MissionDatagrid, string>
                    content={getTestsTooltip}
                    params={params}
                  >
                    <FlexRow>
                      {sampleTypeIcons.map((icon) => (
                        <Spacer key={icon.id} $marginRight=".5rem">
                          <MaterialIcon
                            icon={icon.name || ''}
                            color={icon?.color?.(params.data)}
                            size={icon.size}
                          />
                        </Spacer>
                      ))}
                    </FlexRow>
                  </PopoverCellRenderer>
                ) : (
                  sampleTypeColumn.cellRenderer(params)
                ),
            },
            {
              headerName: t('Done Date'),
              valueGetter: ({ data }: any) =>
                data.resultPerformedAt
                  ? formatDateTime(data.resultPerformedAt, 'DATE_SHORT')
                  : data.resultCreatedAt
                    ? formatDateTime(data.resultCreatedAt, 'DATE_SHORT')
                    : t('Open'),
            },
          ],
        }),
        reports: () => ({
          title: t('Control Reports'),
          columns: [
            {
              headerName: t('Test ID'),
              field: 'testsId',
              sortable: true,
              sort: 'desc',
              width: 80,
            },
            {
              headerName: t('DCO'),
              field: 'dcoName',
            },
            getAthleteColumn(),
            {
              headerName: t('Created'),
              valueGetter: (params: any) => formatDateTime(params.data.createdAt, 'DATE_SHORT'),
              field: 'createdAt',
            },
          ],
        }),

        uaReports: () => ({
          title: t('UA Reports'),
          columns: [
            {
              headerName: t('Test ID'),
              field: 'testsId',
              sortable: true,
              sort: 'desc',
              width: 80,
            },
            {
              headerName: t('DCO'),
              field: 'dcoName',
            },
            getAthleteColumn(),
            {
              headerName: t('Created'),
              valueGetter: (params: any) => formatDateTime(params.data.createdAt, 'DATE_SHORT'),
              field: 'createdAt',
            },
          ],
        }),

        cocs: () => ({
          title: t('CoCs'),
          columns: [
            {
              headerName: t('No.'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 80,
            },
            {
              valueGetter: (params: any) => params.data.lab && params.data.lab.shortName,
              headerName: t('Lab'),
              field: 'lab',
            },
            {
              valueGetter: (params: any) =>
                params.data.personalDelivery ? t('Personal') : t('Courier'),
              headerName: t('Delivery'),
              field: 'deliveryCustom',
            },
            {
              valueGetter: (params: { data: Coc }) =>
                params.data.shippedAt
                  ? formatDateTime(params.data.shippedAt, 'DATE_SHORT')
                  : t('N/A'),
              headerName: t('Date'),
              field: 'shippedAt',
              width: 200,
            },
            {
              headerName: t('Waybill'),
              field: 'waybill',
            },
          ],
        }),

        samples: () => ({
          title: t('Samples'),
          columns: [
            {
              headerName: t('Sample ID'),
              sortable: true,
              sort: 'desc',
              field: 'id',
              width: 120,
            },
            {
              headerName: t('Code'),
              field: 'code',
            },
            {
              ...sampleTypeColumn,
              headerName: t('Type'),
              field: 'sampleType',
              cellRendererParams: {
                icons: sampleTypesToGrid(),
              },
            },
            {
              headerName: t('Stamp'),
              field: 'performedAt',
              valueGetter: (params: { data: Sample }) =>
                params.data.performedAt
                  ? formatDateTime(params.data.performedAt, 'DATE_SHORT')
                  : t('N/A'),
              width: 200,
            },
          ],
        }),
      }) as const,
    [
      t,
      formatDateTime,
      getAthleteColumn,
      sampleTypeIcons,
      sampleTypeColumn,
      showAnalysesToAssignedDco,
      getTestsTooltip,
    ]
  );
};

export default useTableDefinitions;
