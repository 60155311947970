import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const RoleCell = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  min-width: 3rem;
  min-height: 3rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  & > * {
    transform: scale(0.75);
  }
`;

export const ErrorIcon = styled(RoleCell)`
  width: 3rem;
  max-width: 3rem;

  & > * {
    transform: none;
  }
`;
